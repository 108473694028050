import React, { useState } from 'react'

const PlansContext = React.createContext({
  plans: [],
  selectedPlan: () => {},
  setPlans: (plans) => {}
})

const retrieveStoredPlans = () => {
  const jsonPlansObject = localStorage.getItem('plans')
  return JSON.parse(jsonPlansObject);
}

export const PlansContextProvider = props => {
  const plansData = retrieveStoredPlans()

  let initialPlansData = {}

  if (plansData) {
    initialPlansData = plansData
  }

  const [plans, setPlans] = useState(initialPlansData)

  const setPlansHandler = (plans) => {
    setPlans(plans)

    const jsonPlansObj = JSON.stringify(plans)

    localStorage.setItem('plans', jsonPlansObj)
  }

  const contextValue = {
    plans: plans,
    setPlans: setPlansHandler
  }

  return (
    <PlansContext.Provider value={contextValue}>
      {props.children}
    </PlansContext.Provider>
  )
}

export default PlansContext
