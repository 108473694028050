import ContainerTitle from './ContainerTitle'

const Container = props => {
  return (
    <section className={props.classes}>
      {props.header && (
        <ContainerTitle classes={props.header.classes} title={props.header.title} withAvatar={!!props.withAvatar | false}
                        userLogo={props.userLogo} subtitle={props.subtitle} containerClasses={props.containerClasses}/>
      )}
      {props.children}
    </section>
  )
}

export default Container
