import CompanyFeaturedWoowsPage from './pages/Company/CompanyFeaturedWoowsPage'
import CompanyDashboardPage from './pages/Company/CompanyDashboardPage'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import RecoveryPasswordPage from './pages/Auth/RecoveryPasswordPage'
import CompanyProfilePage from './pages/Company/CompanyProfilePage'
import CompanyNewUserPage from './pages/Company/CompanyNewUserPage'
import AdminDashboardPage from './pages/Admin/AdminDashboardPage'
import ProtectedRoute from './components/Routes/ProtectedRoute'
import PreviewWoowPage from './pages/Company/PreviewWoowPage'
import CompanyUserPage from './pages/Company/CompanyUserPage'
import { ToastProvider } from 'react-toast-notifications'
import CompanyContext from './store/company-context'
import RegisterPage from './pages/Auth/RegisterPage'
import LoginPage from './pages/Auth/LoginPage'
import AuthContext from './store/auth-context'
import { useContext } from 'react'

import './styles/output.css'
import './App.css'

function App () {
  const companyCtx = useContext(CompanyContext)
  const authCtx = useContext(AuthContext)

  const history = useHistory()

  const isLoggedIn = authCtx.isLoggedIn
  const isAdmin = companyCtx.isAdmin

  const logoutHandler = () => {
    authCtx.logout()
    history.push('/login')
  }

  return (
    <ToastProvider>
        <Switch>
          <Route path={'/'} history={history} exact>
            {!isLoggedIn && (
              <Redirect to={'/login'}/>
            )}
            {isLoggedIn && isAdmin && (
              <AdminDashboardPage isAdmin={isAdmin}/>
            )}
            {isLoggedIn && !isAdmin && (
              <CompanyDashboardPage isAdmin={isAdmin}/>
            )}
          </Route>
          <ProtectedRoute path={'/new-user'} component={CompanyNewUserPage} loggedIn={isLoggedIn}/>
          <ProtectedRoute path={'/user/:userId'} component={CompanyUserPage} loggedIn={isLoggedIn}/>
          <ProtectedRoute path={'/profile'} component={CompanyProfilePage} loggedIn={isLoggedIn}/>
          <ProtectedRoute path={'/featured-woows'} component={CompanyFeaturedWoowsPage} loggedIn={isLoggedIn}/>
          <Route path={'/register'} component={RegisterPage}/>
          <Route path={'/login'} component={LoginPage}/>
          <Route path={'/recovery-password'} component={RecoveryPasswordPage}/>
          <ProtectedRoute path={'/logout'} loggedIn={isLoggedIn}>
            {logoutHandler}
          </ProtectedRoute>
          <Route path="/woow/:uuid" component={PreviewWoowPage}/>
          <Route path="*">
            {isLoggedIn && isAdmin && (
              <AdminDashboardPage isAdmin={isAdmin}/>
            )}
            {isLoggedIn && !isAdmin && (
              <CompanyDashboardPage isAdmin={isAdmin}/>
            )}
          </Route>
        </Switch>
    </ToastProvider>
  )
}

export default App
