import { CompanyContextProvider } from './store/company-context'
import { PlansContextProvider } from './store/plans-context'
import { AuthContextProvider } from './store/auth-context'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom'
import React from 'react'
import App from './App'

import './index.css'

ReactDOM.render(
  <AuthContextProvider>
    <CompanyContextProvider>
      <PlansContextProvider>
        <BrowserRouter>
          <React.StrictMode>
            <App/>
          </React.StrictMode>
        </BrowserRouter>
      </PlansContextProvider>
    </CompanyContextProvider>
  </AuthContextProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
