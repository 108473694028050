import ButtonWithLeadingIcon from '../../UI/ButtonWithLeadingIcon'
import { sendRequest } from '../../../Services/RequestService'
import CompanyContext from '../../../store/company-context'
import PlansContext from '../../../store/plans-context'
import { useToasts } from 'react-toast-notifications'
import AuthContext from '../../../store/auth-context'
import LoadingSpinner from '../../UI/LoadingSpinner'
import PaymentCard from '../../Stripe/PaymentCard'
import { capitalize } from '../../Helpers/utils'
import { useHistory } from 'react-router-dom'
import { useContext, useState } from 'react'
import AlertModal from '../../UI/AlertModal'
import PlanCard from '../../UI/PlanCard'

const actualPlanMock = {
  'id': '',
  'amount': 0,
  'decimals': '2',
  'currency': 'eur',
  'interval': 'month',
  'name': 'WOOW Basic',
  'product': {
    'id': 'default_plan',
    'name': 'WOOW Basic',
    'description': 'Hasta 10 usuarios',
    'metadata': {
      'limit_amount': '10',
      'front_button_text': 'Dejar de ser PRO',
      'front_background_class': 'bg-indigo-50',
      'front_text_class': 'text-secondary-default'
    }
  }
}

const PlanProfile = () => {
  const [open, setOpen] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [openStripeModal, setOpenStripeModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState()

  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)
  const plansCtx = useContext(PlansContext)
  const authToken = authCtx.authToken

  let companyPlan = actualPlanMock

  if (companyCtx.company.subscription) {
    for (let plan of plansCtx.plans) {
      if (companyCtx.company.subscription.stripe_plan === plan.plan_id) {
        companyPlan = plan
      }
    }
  }

  const userStr = capitalize(companyCtx.customer, true)

  const {addToast} = useToasts()

  const history = useHistory()

  const formatPriceStr = (plan) => {
    if (plan.amount === 0) {
      return '0'
    }

    const price = plan.amount.toString()
    const decimals = plan.decimals || 2

    return price.substring(0, price.length - decimals) + ',' + price.substring(price.length - decimals)
  }

  const deleteAccountModal = () => {
    setOpen(!open)
  }

  const cancelStripeModal = () => {
    setOpenStripeModal(!openStripeModal)
  }

  const deleteAccountHandler = () => {
    const url = process.env.REACT_APP_API_URL + '/delete-account'
    sendRequest(authToken, url, 'POST').then(data => {
      setOpen(!open)
      addToast(data.message, {appearance: 'success', autoDismiss: true,})
      authCtx.logout()
      history.push('/register')
    })
  }

  const updateSelectedPlan = plan => {
    setSelectedPlan(plan)
    setOpenStripeModal(!openStripeModal)
  }

  const updateUserPlan = (paymentMethod, stripe, cardElement) => {
    setProcessingPayment(true)

    fetch(process.env.REACT_APP_API_URL + '/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: JSON.stringify({
        selected_plan: selectedPlan.product.name,
        actual_plan: companyPlan,
        plan_id: selectedPlan.plan_id,
        payment_method: paymentMethod
      })
    }).then(res => {
      if (res.status === 299) {
        return res.json().then(data => {
          data.is3dSecure = true
          throw data
        })
      } else if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then(data => {
      setProcessingPayment(false)
      companyCtx.setCompany(data.data)
      addToast('¡Su plan ha sido actualizado!', {appearance: 'success', autoDismiss: true})
      setOpenStripeModal(!openStripeModal)
    }).catch(err => {
      if (err.is3dSecure) {
        confirmPayment(stripe, err.client_secret, cardElement, paymentMethod).then(() => {
          fetch(process.env.REACT_APP_API_URL + '/companies/' + companyCtx.company.id, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': authCtx.authToken
            }
          }).then(res => {
            if (res.ok) {
              return res.json()
            } else {
              return res.json().then(data => {
                throw data
              })
            }
          }).then(data => {
            setProcessingPayment(false)
            companyCtx.setCompany(data.data)
            addToast('¡Su plan ha sido actualizado!', {appearance: 'success', autoDismiss: true})
            setOpenStripeModal(!openStripeModal)
          })
        }).catch(err => {
          setProcessingPayment(false)
          addToast(err.message, {appearance: 'error', autoDismiss: true})
        })
      } else {
        setProcessingPayment(false)
        addToast(err.message, {appearance: 'error', autoDismiss: true})
      }
    })
  }

  const confirmPayment = async (stripe, clientSecret, cardElement, paymentMethod) => {
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: paymentMethod.billing_details
      }
    })

    if (payload.error) {
      addToast(payload.error.message, {appearance: 'error', autoDismiss: true,})
    } else {
      return payload
    }
  }

  const unsubscribePlan = () => {
    fetch(process.env.REACT_APP_API_URL + '/unsubscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: JSON.stringify({
        plan: companyPlan
      })
    }).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        const errorMessage = 'No se ha podido realizar la acción'
        throw new Error(errorMessage)
      }
    }).then(data => {
      companyCtx.setCompany(data.data)
      addToast('¡Su plan ha sido actualizado!', {appearance: 'success', autoDismiss: true,})
    }).catch(err => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  return (
    <div className="items-center justify-center shadow-md bg-white w-full pt-6 pb-4 px-10 gap-4">

      <div className="w-full">
        <p className="font-lexendSemiBold text-xl">Plan Actual:</p>
        <PlanCard key={companyPlan.name} plan={companyPlan} usrStr={userStr} disableButton={true}
                  updatePlanHandler={updateSelectedPlan} unsubscribeHandler={unsubscribePlan}
                  price={formatPriceStr(companyPlan)}/>
      </div>

      <div className="w-full">
        <p className="font-lexendSemiBold text-xl">Planes disponibles:</p>
        <div className="md:flex md:space-x-4">
          {plansCtx.plans.length > 0 && (
            plansCtx.plans.map((plan) => {
              return plan.product.name !== companyPlan.product.name &&
                <PlanCard key={plan.product.name} usrStr={userStr} plan={plan} price={formatPriceStr(plan)}
                          updatePlanHandler={updateSelectedPlan} unsubscribeHandler={unsubscribePlan}/>
            })
          )}
          {plansCtx.length <= 0 && <LoadingSpinner/>}
        </div>
      </div>

      <div className="w-full">
        <ButtonWithLeadingIcon text={'Dar de baja mi cuenta'} click={deleteAccountModal}
                               classes={'bg-white text-secondary-default px-7 border-gray-100'}/>
      </div>
      {openStripeModal &&
      <PaymentCard key={'paymentModal'} open={openStripeModal} onCancel={cancelStripeModal} confirmLabel={'Aceptar'}
                   dismissLabel={'Cancelar'} usrStr={userStr} plan={selectedPlan} processPayment={updateUserPlan}
                   company={companyCtx.company} processingPayment={processingPayment} auth={authCtx.authToken}/>}
      {open && <AlertModal key={'modal'} open={open} onCancel={deleteAccountModal} onDeleteAction={deleteAccountHandler}
                           title={'Desactivar Cuenta'} confirmLabel={'Desactivar'} dismissLabel={'Cancelar'}
                           description={'¿Estás seguro de desactivar tu cuenta? Todos tus datos se eleminarán permanentemente de nuestros servidores.'}/>}
    </div>
  )
}

export default PlanProfile
