import BeforeAfterGeneratorModal from '../BeforeAfter/BeforeAfterGeneratorModal'
import { createRef, useContext, useEffect, useRef, useState } from 'react'
import ButtonWithLeadingIcon from '../../UI/ButtonWithLeadingIcon'
import WoowGeneratorModal from '../Woows/WoowGeneratorModal'
import TreatmentFormModal from '../../UI/TreatmentFormModal'
import GalleryPhotoInput from '../../UI/GalleryPhotoInput'
import CompanyContext from '../../../store/company-context'
import AuthContext from '../../../store/auth-context'
import { useToasts } from 'react-toast-notifications'
import ImageCropModal from '../../UI/ImageCropModal'
import FullImageModal from '../../UI/FullImageModal'
import { PlusIcon } from '@heroicons/react/outline'
import ItemsGallery from '../../UI/ItemsGallery'
import AlertModal from '../../UI/AlertModal'
import Image from 'react-image-resizer'
import { capitalize } from '../../Helpers/utils'

const UserPhotos = props => {
  const [open, setOpen] = useState()

  const {addToast} = useToasts()

  const companyCtx = useContext(CompanyContext)
  const authCtx = useContext(AuthContext)

  const treatmentDescriptionInputRef = useRef()
  const treatmentTitleInputRef = useRef()

  const [openBeforeAfterGeneratorModal, setOpenBeforeAfterGeneratorModal] = useState(false)
  const [openWoowGeneratorModal, setOpenWoowGeneratorModal] = useState(false)
  const [treatmentIdentifier, setTreatmentIdentifier] = useState(null)
  const [openFullImageModal, setOpenFullImageModal] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [woowCompanyLogo, setWoowCompanyLogo] = useState(null)
  const [imageInputRefs, setImageInputRefs] = useState([])
  const [treatmentDescription, setTreatmentDescription] = useState()
  const [updateTreatmentId, setUpdateTreatmentId] = useState()
  const [deleteTreatmentId, setDeleteTreatmentId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [treatments, setTreatments] = useState([])
  const [imageSrc, setImageSrc] = useState(null)
  const [fullImageModal, setFullImageModal] = useState()
  const [treatmentTitle, setTreatmentTitle] = useState()
  const [refIndex, setRefIndex] = useState()
  const [errors, setErrors] = useState({
    title: '',
    description: '',
  })

  let treatmentsLength = treatments.length

  useEffect(() => {
    setTreatments(props.user.treatments)
  }, [props.user.treatments])

  useEffect(() => {
    const logo = companyCtx.company.logos.find(logo => {
      return logo.type === 'woow'
    }, [])

    setWoowCompanyLogo(logo)
  }, [companyCtx.company.logos])

  useEffect(() => {
    setImageInputRefs(imageInputRefs => (
      Array(treatmentsLength).fill().map((_, i) => imageInputRefs[i] || createRef())
    ))

  }, [treatmentsLength])

  const capitalTreatmentStr = capitalize(companyCtx.treatment, false)
  const treatment = companyCtx.treatment

  let createMessage = capitalTreatmentStr + ' creado con éxito'
  let renameMessage = capitalTreatmentStr + ' renombrado con éxito'
  let deleteMessage = capitalTreatmentStr + ' eliminado con éxito'
  let newTreatmentMessage = 'Nuevo ' + capitalTreatmentStr
  let newTreatmentModalTitle = 'Crear nuevo ' + capitalTreatmentStr
  let newTreatmentModalDescription = 'Indique el título del ' + treatment + ' y una descripción'
  let updateTreatmentModalDescription = 'Indique el nuevo título del ' + treatment + ' y una descripción'
  let deleteTreatmentModalDescription = '¿Está seguro de que desea eliminar este ' + treatment + '?'

  if (treatment === 'obra') {
    createMessage = capitalTreatmentStr + ' creada con éxito'
    renameMessage = capitalTreatmentStr + ' renombrada con éxito'
    deleteMessage = capitalTreatmentStr + ' eliminada con éxito'
    newTreatmentMessage = 'Nueva ' + capitalTreatmentStr
    newTreatmentModalTitle = 'Crear nueva ' + capitalTreatmentStr
    newTreatmentModalDescription = 'Indique el título de la ' + treatment + ' y una descripción'
    updateTreatmentModalDescription = 'Indique el nuevo título de la ' + treatment + ' y una descripción'
    deleteTreatmentModalDescription = '¿Está seguro de que desea eliminar esta ' + treatment + '?'
  }

  const updateTreatmentModalTitle = 'Actualizar ' + capitalTreatmentStr
  const deleteTreatmentModalTitle = 'Eliminar ' + capitalTreatmentStr

  const formSubmissionHandler = () => {
    const title = treatmentTitleInputRef.current.value
    const description = treatmentDescriptionInputRef.current.value

    fetch(process.env.REACT_APP_API_URL + '/treatments', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: JSON.stringify({
        title: title,
        description: description,
        user_id: props.user.id
      })
    }).then(res => {
      setErrors({
        title: '',
        description: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      props.user.treatments.push(data.treatment)
      companyCtx.setCompany(data.company)
      treatmentsLength = data.treatment.length
      addToast(createMessage, {appearance: 'success', autoDismiss: true})
      setOpenCreateModal(!openCreateModal)
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          title: err.errors.title && err.errors.title[0],
          description: err.errors.description && err.errors.description[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true})
      }
    })
  }

  const updateTreatment = event => {
    event.preventDefault()

    const introducedTitle = treatmentTitleInputRef.current.value
    const introducedDescription = treatmentDescriptionInputRef.current.value

    if (introducedTitle.length < 0) {
      setOpenUpdateModal(!openUpdateModal)
      return
    }

    fetch(process.env.REACT_APP_API_URL + '/treatments/' + updateTreatmentId, {
      method: 'POST',
      body: JSON.stringify({
        _method: 'PATCH',
        title: introducedTitle,
        description: introducedDescription
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setErrors({
        title: '',
        description: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      setTreatments(data.treatments)
      treatmentsLength = data.treatments.length
      props.user.treatments = data.treatments
      companyCtx.setCompany(data.company)
      setTreatmentTitle('')
      setTreatmentDescription('')
      setOpenUpdateModal(!openUpdateModal)
      addToast(renameMessage, {appearance: 'success', autoDismiss: true})
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          title: err.errors.title && err.errors.title[0],
          description: err.errors.description && err.errors.description[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true})
      }
    })
  }

  const deleteTreatment = event => {
    event.preventDefault()

    fetch(process.env.REACT_APP_API_URL + '/treatments/' + deleteTreatmentId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenDeleteModal(!openDeleteModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Delete treatment failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      setTreatments(data.treatments)
      treatmentsLength = data.treatments.length
      props.user.treatments = data.treatments
      companyCtx.setCompany(data.company)
      addToast(deleteMessage, {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelCreate = () => {
    setOpenCreateModal(!openCreateModal)
  }

  const cancelUpdate = () => {
    setOpenUpdateModal(!openUpdateModal)
  }

  const cancelDelete = () => {
    setOpenDeleteModal(!openDeleteModal)
  }

  const createTreatmentHandler = () => {
    setOpenCreateModal(!openCreateModal)
  }

  const updateTreatmentHandler = (consentId, title, description) => {
    setTreatmentTitle(title)
    setTreatmentDescription(description)
    setOpenUpdateModal(!openUpdateModal)
    setUpdateTreatmentId(consentId)
  }

  const deleteTreatmentHandler = consentId => {
    setOpenDeleteModal(!openDeleteModal)
    setDeleteTreatmentId(consentId)
  }

  function readFile (file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const closeCropImageModal = () => {
    setOpen(!open)
  }

  const switchCropImageModal = async (index) => {
    if (imageInputRefs[index].current.files && imageInputRefs[index].current.files.length > 0) {
      const file = imageInputRefs[index].current.files[0]
      let imageDataUrl = await readFile(file)

      setRefIndex(index)
      setImageSrc(imageDataUrl)
      setImageInputRefs(imageInputRefs => (
        Array(treatmentsLength).fill().map((_, i) => imageInputRefs[i] || createRef())
      ))
    }

    setOpen(!open)
  }

  const setIdNumber = (id) => {
    setTreatmentIdentifier(id)
  }

  const uploadImage = (treatmentId, croppedImg, isVerticalImage) => {
    setIsLoading(true)

    const formData = new FormData()

    formData.append('treatment_id', treatmentIdentifier)
    formData.append('cropped_photo', croppedImg)
    formData.append('photo', imageInputRefs[refIndex].current.files[0])
    formData.append('is_vertical_image', isVerticalImage)

    fetch(process.env.REACT_APP_API_URL + '/photos', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: formData,
    }).then(res => {
      setIsLoading(false)

      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Upload photo failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      const treatment = props.user.treatments.find(treatment => {
        return treatment.id === treatmentIdentifier
      })
      treatment.photos.push(data.photo)
      companyCtx.setCompany(data.company)
      setOpen(!open)
      addToast('Imagen subida con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const createBeforeAfterHandler = () => {
    setOpenBeforeAfterGeneratorModal(!openBeforeAfterGeneratorModal)
  }

  const generateBeforeAfterHandler = (img, title, isVerticalImage) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/images', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: JSON.stringify({
        user_id: props.user.id,
        title: title,
        image: img,
        is_vertical_image: isVerticalImage
      }),
    }).then(res => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Before&After image generation failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      props.user.images.push(data.image)
      companyCtx.setCompany(data.company)
      setOpenBeforeAfterGeneratorModal(!openBeforeAfterGeneratorModal)
      addToast('Antes y después creado con éxito', {appearance: 'success', autoDismiss: true})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true})
    })
  }

  const createWoowHandler = () => {
    setOpenWoowGeneratorModal(!openWoowGeneratorModal)
  }

  const generateWoowHandler = (woowObj) => {
    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/woows', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: JSON.stringify({
        user_id: props.user.id,
        title: woowObj.title,
        before_photo: woowObj.before_photo.id,
        after_photo: woowObj.after_photo.id,
        is_vertical_slider: woowObj.is_vertical
      }),
    }).then(res => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'WOOW generation failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      props.user.woows.push(data.data)
      companyCtx.company.woows.push(data.data)
      setOpenWoowGeneratorModal(!openWoowGeneratorModal)
      addToast('WOOW creado con éxito', {appearance: 'success', autoDismiss: true})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true})
    })
  }

  const fullImageHandler = photo => {
    setFullImageModal(photo)
    setOpenFullImageModal(true)
  }

  const closeFullImageHandler = () => {
    setFullImageModal('')
    setOpenFullImageModal(false)
  }

  return (
    <div className="items-center justify-center shadow-md bg-white w-full pt-6 pb-4 px-10 gap-4">

      <div className="w-full">
        <div className={'flex flex-wrap sm:space-x-4'}>
          <ButtonWithLeadingIcon text={newTreatmentMessage}
                                 leadingIcon={<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>}
                                 classes={'bg-blue-100 text-secondary-default px-7'} click={createTreatmentHandler}/>
          <ButtonWithLeadingIcon text={'Generar WooW'} click={createWoowHandler}
                                 classes={'bg-primary-default text-white hover:text-primary-default hover:border-primary-default px-7 ml-2'}/>
          <ButtonWithLeadingIcon text={'Crear Antes y Después'} click={createBeforeAfterHandler}
                                 classes={'bg-primary-default text-white hover:text-primary-default hover:border-primary-default px-7 ml-2'}/>
        </div>

        {treatments.map((treatment, index) => {
          return <ItemsGallery key={treatment.id + '_gallery'} item={treatment} updateHandler={updateTreatmentHandler}
                               deleteHandler={deleteTreatmentHandler}>
            {treatment.photos && treatment.photos.map(photo => {
              return <div key={photo.id + '_div'}
                          onClick={() => fullImageHandler(photo)}
                          className={'border rounded-md my-1 hover:border-primary-default pt-1.5'}>
                {photo && (
                  <Image key={photo.id} height={176} width={176} src={process.env.REACT_APP_ASSET_URL + photo.path}
                         alt={photo.filename} noImageAlt={'Image not found'} style={{
                    'margin': '0 auto',
                    'height': '11rem',
                    'width': '11rem',
                    'background': '#eef2ff',
                    'borderRadius': '0.375rem'
                  }}/>
                )}
              </div>
            })}
            {treatment.photos && treatment.photos.length <= 11 && (
              <GalleryPhotoInput key={treatment.id + '_gallery_input'} name={'treatment-photo'} accept={'image/*'}
                                 setterId={setIdNumber} btnText={<PlusIcon className="h-10 w-10 cursor-pointer"/>}
                                 inputHandler={switchCropImageModal} idNumber={treatment.id}
                                 ref={imageInputRefs[index]}
                                 btnClasses={'bg-indigo-50 text-secondary-default h-44 w-44 border-gray-100 inline-flex'}
                                 withoutFileName={true} index={index}/>
            )
            }
          </ItemsGallery>
        })}
      </div>
      {openCreateModal &&
      <TreatmentFormModal key={'create-treatment-modal'} open={openCreateModal} onCancel={cancelCreate}
                          onAction={formSubmissionHandler} titleErrorMessage={errors.title}
                          titleRef={treatmentTitleInputRef} confirmLabel={'Crear'} dismissLabel={'Cancelar'}
                          descriptionRef={treatmentDescriptionInputRef} title={newTreatmentModalTitle}
                          description={newTreatmentModalDescription}/>}
      {openUpdateModal &&
      <TreatmentFormModal key={'update-treatment-modal'} open={openUpdateModal} onCancel={cancelUpdate}
                          onAction={updateTreatment} descriptionErrorMessage={errors.description}
                          titleRef={treatmentTitleInputRef} confirmLabel={'Actualizar'} dismissLabel={'Cancelar'}
                          descriptionRef={treatmentDescriptionInputRef} title={updateTreatmentModalTitle}
                          description={updateTreatmentModalDescription}
                          previousTitleValue={treatmentTitle} previousDescriptionValue={treatmentDescription}
                          titleErrorMessage={errors.title}/>
      }
      {openDeleteModal &&
      <AlertModal key={'modal'} open={openDeleteModal} onCancel={cancelDelete} title={deleteTreatmentModalTitle}
                  onDeleteAction={deleteTreatment} confirmLabel={'Eliminar'} dismissLabel={'Cancelar'}
                  description={deleteTreatmentModalDescription}/>
      }
      {open &&
      <ImageCropModal open={open} img={imageSrc} onCancel={closeCropImageModal} onSubmit={uploadImage}
                      confirmLabel={'Subir'} dismissLabel={'Cancelar'} treatmentId={treatmentIdentifier}
                      isLoading={isLoading}/>
      }
      {openWoowGeneratorModal &&
      <WoowGeneratorModal open={openWoowGeneratorModal} onCancel={createWoowHandler}
                          onAction={generateWoowHandler}
                          treatments={treatments} confirmLabel={'Crear WooW'} dismissLabel={'Cancelar'}
                          isLoading={isLoading} treatmentLabel={capitalTreatmentStr} title={'Generador de WOOW'}
                          description={'Selecciona las imágenes que quieres utilizar y la posición del slider'}
                          logo={woowCompanyLogo}
                          treatmentStr={companyCtx.treatment}/>
      }
      {openBeforeAfterGeneratorModal &&
      <BeforeAfterGeneratorModal open={openBeforeAfterGeneratorModal} onCancel={createBeforeAfterHandler}
                                 onAction={generateBeforeAfterHandler}
                                 treatments={treatments} confirmLabel={'Crear Antes y Después'}
                                 dismissLabel={'Cancelar'}
                                 isLoading={isLoading} treatmentLabel={capitalTreatmentStr}
                                 title={'Generador de Antes y Después'}
                                 description={'Selecciona las imágenes que quieres utilizar'}
                                 logo={woowCompanyLogo}
                                 treatmentStr={companyCtx.treatment}/>
      }
      {openFullImageModal &&
      <FullImageModal open={openFullImageModal} onCancel={closeFullImageHandler} dismissLabel={'Cerrar'}
                      image={fullImageModal}/>
      }
    </div>
  )
}

export default UserPhotos
