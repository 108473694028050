import { Dialog, Transition } from '@headlessui/react'
import ReactCompareImage from 'react-compare-image'
import { Fragment, useRef } from 'react'

const WoowPreviewModal = (props) => {
  const cancelButtonRef = useRef()

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.open}
        onClose={props.onCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-900 bg-opacity-95 transition-opacity"/>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-4xl w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                  <div className="w-full">
                    <div className={'flex flex-wrap content-center justify-center'}>
                      <img src={process.env.REACT_APP_ASSET_URL + props.woow.before_photo.path}
                           alt={props.woow.before_photo.filename} className={'ml-2 my-3 rounded-md h-16 w-16'}
                           key={'preview-' + props.woow.before_photo.id}/>
                      <img src={process.env.REACT_APP_ASSET_URL + props.woow.after_photo.path}
                           alt={props.woow.after_photo.filename} className={'ml-2 my-3 rounded-md h-16 w-16'}
                           key={'preview-' + props.woow.after_photo.id}/>
                      <ReactCompareImage leftImage={process.env.REACT_APP_ASSET_URL + props.woow.before_photo.path}
                                         rightImage={process.env.REACT_APP_ASSET_URL + props.woow.after_photo.path}
                                         vertical={props.woow.is_vertical_slider} sliderLineWidth={6} aspectRatio="wider"
                                         leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                         rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>
                      {props.logo && (
                        <img className="h-16 w-auto mt-5 mb-3" src={process.env.REACT_APP_ASSET_URL + props.logo.path}
                             alt={'Company Logo'}/>
                      )}
                      {!props.logo && (
                        <img
                          className="h-10 w-auto mt-5 mb-3"
                          src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                          alt="WOOW logo"/>
                      )}
                      <p className={'text-xl text-center w-full'}>{props.woow.title}</p>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={props.onCancel}
                      ref={cancelButtonRef}
                    >
                      {props.dismissLabel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default WoowPreviewModal
