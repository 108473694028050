const PlanCard = props => {
  const fromToStr = planTitle => {
    switch (planTitle) {
      case 'WOOW Basic':
        return '1 mes gratis'
      case 'WOOW Pro':
        return 'Hasta ' + props.plan.product.metadata.limit_amount + ' ' + props.usrStr
      case 'WOOW Pro Hero':
        return props.usrStr + ' ilimitados'
      default:
        return ''
    }
  }

  return (
    <div
      className={'xs:w-full md:w-1/2 md:inline-block max-w-md my-3 px-3 bg-gray-50 rounded-lg ' + props.plan.product.metadata.front_background_class + ' ' + props.plan.product.metadata.front_text_class}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-lexendSemiBold text-2xl">{props.plan.product.name}</h3>
        <div className="mt-2 max-w-xl">
          <p>- {fromToStr(props.plan.product.name)}</p>
        </div>
        <hr className={'mt-3'}/>
        <div className="md:flex flex-wrap items-center justify-between">
          <p className="inline-block font-lexendSemiBold text-2xl mt-5">
            {props.plan.amount === 0 ? 'GRATIS' : '€ ' + props.price + ' /mes'}
          </p>
          {!props.disableButton && (
            <button
              type="button"
              onClick={() => props.plan.amount === 0 ? props.unsubscribeHandler() : props.updatePlanHandler(props.plan)}
              className="w-full px-5 py-3 mt-5 rounded-md font-lexendSemiBold text-secondary-default bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-default sm:text-sm lg:w-1/3"
            >
              {props.plan.product.metadata.front_button_text}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PlanCard
