import { Carousel } from 'react-responsive-carousel'
import ReactCompareImage from 'react-compare-image'
import { useMediaQuery } from 'react-responsive'

import arBefore from '../assets/ar_b.png'
import arAfter from '../assets/ar_a.png'
import deBefore from '../assets/de_b.png'
import deAfter from '../assets/de_a.png'
import haBefore from '../assets/ha_b.png'
import haAfter from '../assets/ha_a.png'
import meBefore from '../assets/me_b.jpg'
import meAfter from '../assets/me_a.jpg'
import odBefore from '../assets/od_b.jpg'
import odAfter from '../assets/od_a.jpg'

import './auth-layout-styles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const AuthLayout = (props) => {
  const isDesktopOrLaptop = useMediaQuery(
    {minDeviceWidth: 1224},
    {deviceWidth: 1600} // `device` prop
  )

  const isDesktopOrLaptopLayout = useMediaQuery(
    {minDeviceWidth: 591},
    {deviceWidth: 1600} // `device` prop
  )

  return (
    <main className={'flex flex-wrap'}>
      {isDesktopOrLaptopLayout &&
        <section className={'mx-auto place-content-center md:w-1/2 sm:w-full md:min-h-screen'}>
          <div className={'lg:absolute sm:top-0 sm:left-6 mx-auto'}>
            <a href='https://woowphoto.com' target={'_blank'} rel={'noopener noreferrer'}>
              <img
                className="h-10 w-auto mt-5 ml-5"
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="logo"/>
            </a>
          </div>
          {props.children}
        </section>
      }
      {!isDesktopOrLaptopLayout &&
        <section
          className={'flex flex-wrap mx-auto overflow-hidden place-content-center md:w-1/2 sm:w-full md:min-h-screen'}>
          <div className={'lg:absolute sm:top-0 sm:left-6'}>
            <img
              className="h-10 w-auto mt-5 ml-5"
              style={{'width': `${process.env.REACT_APP_CAROUSEL_IMG_WIDTH}%`}}
              src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
              alt="logo"/>
          </div>
          {props.children}
        </section>
      }
      <section
        className={'flex flex-wrap overflow-hidden place-content-center md:w-1/2 sm:w-0 bg-primary-default content-center pt-5 md:min-h-screen'}>
        {isDesktopOrLaptop &&
          <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} stopOnHover={true}>
            <div className={'bg-white p-4 rounded-md'}>
              <ReactCompareImage leftImage={arBefore} rightImage={arAfter}
                                 vertical={true} sliderLineWidth={6} aspectRatio="wider"
                                 leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                 rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>

              <img
                className="h-10 w-auto mt-5"
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="logo"/>
            </div>
            <div className={'bg-white p-4 rounded-md'}>
              <ReactCompareImage leftImage={deBefore} rightImage={deAfter}
                                 vertical={false} sliderLineWidth={6} aspectRatio="wider"
                                 leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                 rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>

              <img
                className="h-10 w-auto mt-5"
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="logo"/>
            </div>
            <div className={'bg-white p-4 rounded-md'}>
              <ReactCompareImage leftImage={haBefore} rightImage={haAfter}
                                 vertical={false} sliderLineWidth={6} aspectRatio="wider"
                                 leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                 rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>
              <img
                className="h-10 w-auto mt-5"
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="logo"/>
            </div>
            <div className={'bg-white p-4 rounded-md'}>
              <ReactCompareImage leftImage={meBefore} rightImage={meAfter}
                                 vertical={false} sliderLineWidth={6} aspectRatio="wider"
                                 leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                 rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>

              <img
                className="h-10 w-auto mt-5"
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="logo"/>
            </div>
            <div className={'bg-white p-4 rounded-md'}>
              <ReactCompareImage leftImage={odBefore} rightImage={odAfter}
                                 vertical={false} sliderLineWidth={6} aspectRatio="wider"
                                 leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                 rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>

              <img
                className="h-10 w-auto mt-5"
                style={{'width': `${process.env.REACT_APP_CAROUSEL_IMG_WIDTH}%`}}
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="logo"/>
            </div>
          </Carousel>
        }

        {!isDesktopOrLaptop &&
          <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={false} stopOnHover={true}>
            <div className={'bg-white p-4 rounded-md'}>
              <ReactCompareImage leftImage={arBefore} rightImage={arAfter}
                                 vertical={true} sliderLineWidth={6} aspectRatio="wider"
                                 leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                 rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>

              <img
                className="h-10 w-auto mt-5"
                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                alt="WOOW logo"/>
            </div>
          </Carousel>
        }

        <p className="text-xl text-white font-semibold text-center md:mx-12 xl:mx-24 my-10 px-3">
          Profesionaliza la gestión de tus fotos Antes y Después de forma sencilla y segura.<br/>
          Eleva la conversación mostrando los resultados de una forma innovadora.
        </p>
      </section>
    </main>
  )
}

export default AuthLayout
