import ButtonWithLeadingIcon from '../../UI/ButtonWithLeadingIcon'
import { Fragment, useContext, useRef, useState } from 'react'
import CompanyContext from '../../../store/company-context'
import AuthContext from '../../../store/auth-context'
import { useToasts } from 'react-toast-notifications'
import InputWithError from '../../UI/InputWithError'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { useParams } from 'react-router-dom'
import FileInput from '../../UI/FileInput'
import UserConsents from './UserConsents'

const UserInformation = props => {
  const {userId} = useParams()
  const {addToast} = useToasts()
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)

  const [user, setUser] = useState(props.user)
  const [showConsents, setShowConsents] = useState(false)

  const identificationNumberInputRef = useRef(user.identification_number)
  const contactNumberInputRef = useRef(user.contact_number)
  const lastNameInputRef = useRef(user.last_name)
  const emailInputRef = useRef(user.email)
  const nameInputRef = useRef(user.name)
  const userPhotoInputRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    user_photo: '',
    name: '',
    last_name: '',
    identification_number: '',
    contact_number: '',
    email: ''
  })

  const formSubmissionHandler = (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('_method', 'PATCH')
    formData.append('name', nameInputRef.current.value)
    formData.append('last_name', lastNameInputRef.current.value)

    if (identificationNumberInputRef.current.value) {
      formData.append('identification_number', identificationNumberInputRef.current.value)
    }
    if (contactNumberInputRef.current.value) {
      formData.append('contact_number', contactNumberInputRef.current.value)
    }
    if (emailInputRef.current.value) {
      formData.append('email', emailInputRef.current.value)
    }
    formData.append('user_photo', userPhotoInputRef.current.files[0])

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/users/' + userId, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        user_photo: '',
        name: '',
        last_name: '',
        identification_number: '',
        contact_number: '',
        email: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      setUser(data.user)
      props.onUpdate(data.user)
      const foundIndex = companyCtx.company.users.findIndex(x => x.id === data.user.id)
      companyCtx.company.users[foundIndex] = data.user
      companyCtx.setCompany(data.company)
      addToast('¡Ficha actualizada!', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          user_photo: err.errors.user_photo && err.errors.user_photo[0],
          name: err.errors.name && err.errors.name[0],
          last_name: err.errors.last_name && err.errors.last_name[0],
          identification_number: err.errors.identification_number && err.errors.identification_number[0],
          contact_number: err.errors.contact_number && err.errors.contact_number[0],
          email: err.errors.email && err.errors.email[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  const showConsentsHandler = () => {
    setShowConsents(!showConsents)
  }

  return (
    <Fragment>
      {!showConsents &&
      <form onSubmit={formSubmissionHandler} encType={'multipart/form-data'}>
        <div className="flex items-center justify-center">
          <div className="bg-white w-full px-10">
            <div
              className="flex flex-wrap md:grid md:grid-cols-3 md:space-y-0 space-y-1 mt-1 flex pt-6 items-center justify-center">
            <span className="h-24 w-24 rounded-full overflow-hidden bg-gray-100 items-center">
              {!user.avatar && (
                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                </svg>
              )}
              {user.avatar && (
                <img className="m-auto h-full max-w-none" src={process.env.REACT_APP_ASSET_URL + user.avatar}
                     alt={'Company Logo'}/>
              )}
            </span>
              <FileInput key={'user-photo'} name={'user-photo'}
                         btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex sm:ml-5 md:ml-0'}
                         withoutFileName={false} btnText={'Reemplazar'} ref={userPhotoInputRef}/>
            </div>
            <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
              <p className={'font-lexendSemiBold text-lg'}>Nombre<span style={{'color': 'red'}}>*</span>:</p>
              <InputWithError label={''} type={'text'} name={'name'} id={'first-name-input'}
                              setRef={nameInputRef} placeholder={''} default={user.name}
                              errorMessage={errors.name} description={'nombre del usuario'} required={true}/>
            </div>
            <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
              <p className={'font-lexendSemiBold text-lg'}>Apellidos<span style={{'color': 'red'}}>*</span>:</p>
              <InputWithError label={''} type={'text'} name={'last_name'} id={'last-name-input'}
                              setRef={lastNameInputRef} placeholder={''} default={user.last_name}
                              errorMessage={errors.last_name} description={'apellidos del usuario'} required={true}/>
            </div>
            <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
              <p className={'font-lexendSemiBold text-lg'}>DNI:</p>
              <InputWithError label={''} type={'text'} name={'identification_number'} id={'identification-number-input'}
                              setRef={identificationNumberInputRef} placeholder={''} description={'dni del usuario'}
                              default={user.identification_number} errorMessage={errors.identification_number}
                              required={true}/>
            </div>
            <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
              <p className={'font-lexendSemiBold text-lg'}>Teléfono:</p>
              <InputWithError label={''} type={'text'} name={'contact_number'} id={'contact-number-input'}
                              setRef={contactNumberInputRef} placeholder={''} default={user.contact_number}
                              errorMessage={errors.contact_number} description={'teléfono del usuario'}
                              required={true}/>
            </div>
            <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 pb-4 items-center">
              <p className={'font-lexendSemiBold text-lg'}>Email:</p>
              <InputWithError label={''} type={'text'} name={'email'} id={'email-input'}
                              setRef={emailInputRef} placeholder={''} default={user.email}
                              errorMessage={errors.email} description={'email del usuario'} required={true}/>
            </div>
            <p>Para agregar o consultar un consentimiento puede hacerlo <span
              className={'text-primary-default font-bold cursor-pointer'} onClick={showConsentsHandler}>
              aquí
            </span></p>
          </div>
        </div>
        <div className={'flex items-center bg-white shadow-md px-10 border-t-2 py-6 px-8 justify-end mt-5'}>
          {!isLoading && (
            <ButtonWithLeadingIcon text={'Actualizar'} leadingIcon={''} click={formSubmissionHandler}
                                   classes={'bg-primary-default text-white px-12 hover:text-primary-default'}/>
          )}
          {isLoading && <LoadingSpinner/>}
        </div>
      </form>
      }
      {showConsents &&
      <UserConsents user={user}/>
      }
    </Fragment>
  )
}

export default UserInformation
