import { HeartIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { HeartIcon as HearthOutline } from '@heroicons/react/outline'
import FullImageModal from '../../UI/FullImageModal'
import { Menu, Transition } from '@headlessui/react'
import ReactCompareImage from 'react-compare-image'
import { classNames } from '../../Helpers/utils'
import { Fragment, useState } from 'react'

const WoowCard = (props) => {
  const [openFullImageModal, setOpenFullImageModal] = useState(false)
  const [fullImageModal, setFullImageModal] = useState()

  const fullImageHandler = photo => {
    setFullImageModal(photo)
    setOpenFullImageModal(true)
  }

  const closeFullImageHandler = () => {
    setFullImageModal('')
    setOpenFullImageModal(false)
  }

  return (
    <div className={classNames(
      props.woow.before_photo.is_vertical_image || props.woow.after_photo.is_vertical_image ? 'md:w-1/2' : 'md:w-1/2',
      'shadow-lg bg-white px-4 py-5 sm:px-6 w-full sm:w-1/2 sm:mx-auto md:mx-0 my-5 rounded-md object-none box-border'
    )}>
      <div className="flex flex-wrap">
        <div className="min-w-0 flex-1">
          <div className={'flex flex-wrap justify-center'}>
            <div onClick={() => fullImageHandler(props.woow.before_photo)} className={'border box-border rounded-md mx-1 my-3 2xl:hover:border-primary-default p-1'}>
              <img src={process.env.REACT_APP_ASSET_URL + props.woow.before_photo.path}
                   alt={props.woow.before_photo.filename} className={'rounded-md h-16 w-16'}
                   key={props.woow.before_photo.id}/>
            </div>
            <div onClick={() => fullImageHandler(props.woow.after_photo)} className={'border rounded-md mx-1 my-3 2xl:hover:border-primary-default p-1'}>
              <img src={process.env.REACT_APP_ASSET_URL + props.woow.after_photo.path}
                   alt={props.woow.after_photo.filename} className={'rounded-md h-16 w-16'}
                   key={props.woow.after_photo.id}/>
            </div>
          </div>
          <ReactCompareImage leftImage={process.env.REACT_APP_ASSET_URL + props.woow.before_photo.path}
                             rightImage={process.env.REACT_APP_ASSET_URL + props.woow.after_photo.path}
                             vertical={props.woow.is_vertical_slider} sliderLineWidth={6} aspectRatio="wider"
                             leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                             rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>
        </div>
      </div>
      <div className={'min-w-0 flex-1'}>
        {!props.woow.is_featured &&
        <HearthOutline className="h-6 w-6 text-primary-default cursor-pointer mt-3" aria-hidden="true"
                       onClick={() => props.woow.is_featured
                         ? props.feature(props.woow.id, false)
                         : props.feature(props.woow.id, true)}/>
        }
        {props.woow.is_featured &&
        <HeartIcon className="h-6 w-6 text-primary-default cursor-pointer mt-3" aria-hidden="true"
                   onClick={() => props.woow.is_featured
                     ? props.feature(props.woow.id, false)
                     : props.feature(props.woow.id, true)}/>
        }

        <div className="float-right">
          <Menu as="div" className="relative inline-block text-left bottom-5">
            {({open}) => (
              <>
                <div>
                  <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.preview(props.woow)}>
                            <span>Visualizar</span>
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.share(props.woow.uuid)}>
                            <span>Compartir</span>
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.woow.is_featured
                              ? props.feature(props.woow.id, false)
                              : props.feature(props.woow.id, true)}>
                            {props.woow.is_featured && <span>Desmarcar destacado</span>}
                            {!props.woow.is_featured && <span>Marcar destacado</span>}
                          </p>
                        )}
                      </Menu.Item>
                      {props.woow.is_featured &&
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.assignFolder(props.woow.folder, props.woow.id)}>
                            <span>Asignar carpeta</span>
                          </p>
                        )}
                      </Menu.Item>
                      }
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.update(props.woow.id)}>
                            <span>Renombrar</span>
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.delete(props.woow.id)}>
                            <span>Eliminar</span>
                          </p>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        <p className={'mt-3'}>{props.woow.title}</p>
      </div>

      {openFullImageModal &&
      <FullImageModal open={openFullImageModal} onCancel={closeFullImageHandler} dismissLabel={'Cerrar'}
                      image={fullImageModal}/>
      }
    </div>
  )
}

export default WoowCard
