import ActionPanelWithInputModal from '../../UI/ActionPanelWithInputModal'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import ButtonWithLeadingIcon from '../../UI/ButtonWithLeadingIcon'
import TreatmentFormModal from '../../UI/TreatmentFormModal'
import CompanyContext from '../../../store/company-context'
import FolderSelectorModal from './FolderSelectorModal'
import AuthContext from '../../../store/auth-context'
import { useToasts } from 'react-toast-notifications'
import { PlusIcon } from '@heroicons/react/outline'
import WoowPreviewModal from './WoowPreviewModal'
import ItemsGallery from '../../UI/ItemsGallery'
import AlertModal from '../../UI/AlertModal'
import ShareUrlModal from './ShareUrlModal'
import WoowCard from './WoowCard'

const FeaturedWoow = props => {
  const {addToast} = useToasts()

  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)

  const folderTitleInputRef = useRef()
  const folderDescriptionInputRef = useRef()
  const woowTitleInputRef = useRef()
  const folderSelectorRef = useRef()

  const [selectedWoowId, setSelectedWoowId] = useState()
  const [woowFolder, setWoowFolder] = useState(null)
  const [openUpdateWoowModal, setOpenUpdateWoowModal] = useState(false)
  const [openDeleteWoowModal, setOpenDeleteWoowModal] = useState(false)

  const [woowCompanyLogo, setWoowCompanyLogo] = useState(null)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openUpdateFolderModal, setOpenUpdateFolderModal] = useState(false)
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [openFolderModal, setOpenFolderModal] = useState(false)
  const [updateWoowFolderId, setUpdateWoowFolderId] = useState()
  const [deleteWoowFolderId, setDeleteWoowFolderId] = useState()
  const [previewWoow, setPreviewWoow] = useState()
  const [folders, setWoowFolders] = useState([])
  const [folderTitle, setWoowFolderTitle] = useState()
  const [folderDescription, setWoowFolderDescription] = useState()
  const [shareUrl, setShareUrl] = useState()
  const [openShareModal, setOpenShareModal] = useState(false)
  const [errors, setErrors] = useState({
    title: '',
    description: '',
  })

  useEffect(() => {
    setWoowFolders(companyCtx.company.woow_folders)
  }, [companyCtx.company.woow_folders])

  useEffect(() => {
    const logo = companyCtx.company.logos.find(logo => {
      return logo.type === 'woow'
    }, [])

    setWoowCompanyLogo(logo)
  }, [companyCtx.company.logos])

  const previewWoowHandler = woow => {
    setPreviewWoow(woow)
    setOpenPreviewModal(!openPreviewModal)
  }

  const checkIfWoowWithoutFolder = () => {
    const woowsWithoutFolder = companyCtx.company.woows.some(woow => {
      return woow.folder === null
    })

    const woowsFeatured = companyCtx.company.woows.some(woow => {
      return woow.is_featured
    })

    return woowsWithoutFolder && woowsFeatured
  }

  const formSubmissionHandler = () => {
    const title = folderTitleInputRef.current.value
    const description = folderDescriptionInputRef.current.value

    fetch(process.env.REACT_APP_API_URL + '/woow-folders', {
      method: 'POST',

      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: JSON.stringify({
        title: title,
        description: description
      })
    }).then(res => {
      setErrors({
        title: '',
        description: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      companyCtx.company.woow_folders.push(data.data)
      setWoowFolders(companyCtx.company.woow_folders)
      addToast('Carpeta creada con éxito', {appearance: 'success', autoDismiss: true})
      setOpenCreateModal(!openCreateModal)
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          title: err.errors.title && err.errors.title[0],
          description: err.errors.description && err.errors.description[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true})
      }
    })
  }

  const updateWoowFolder = event => {
    event.preventDefault()

    const introducedTitle = folderTitleInputRef.current.value
    const introducedDescription = folderDescriptionInputRef.current.value

    if (introducedTitle.length < 0) {
      setOpenUpdateFolderModal(!openUpdateFolderModal)
      return
    }

    fetch(process.env.REACT_APP_API_URL + '/woow-folders/' + updateWoowFolderId, {
      method: 'POST',
      body: JSON.stringify({
        _method: 'PATCH',
        title: introducedTitle,
        description: introducedDescription
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setErrors({
        title: '',
        description: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      setWoowFolders(data.data.woow_folders)
      companyCtx.setCompany(data.data)
      setWoowFolderTitle('')
      setWoowFolderDescription('')
      setOpenUpdateFolderModal(!openUpdateFolderModal)
      addToast('Carpeta renombrada con éxito', {appearance: 'success', autoDismiss: true})
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          title: err.errors.title && err.errors.title[0],
          description: err.errors.description && err.errors.description[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true})
      }
    })
  }

  const deleteWoowFolder = event => {
    event.preventDefault()

    fetch(process.env.REACT_APP_API_URL + '/woow-folders/' + deleteWoowFolderId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenDeleteFolderModal(!openDeleteFolderModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Delete featured failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      setWoowFolders(data.data.woow_folders)
      companyCtx.setCompany(data.data)
      addToast('Carpeta eliminada con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelCreate = () => {
    setOpenCreateModal(!openCreateModal)
  }

  const cancelUpdate = () => {
    setOpenUpdateFolderModal(!openUpdateFolderModal)
  }

  const cancelDelete = () => {
    setOpenDeleteFolderModal(!openDeleteFolderModal)
  }

  const createWoowFolderHandler = () => {
    setOpenCreateModal(!openCreateModal)
  }

  const updateWoowFolderHandler = (folderId, title, description) => {
    setWoowFolderTitle(title)
    setWoowFolderDescription(description)
    setOpenUpdateFolderModal(!openUpdateFolderModal)
    setUpdateWoowFolderId(folderId)
  }

  const deleteWoowFolderHandler = folderId => {
    setOpenDeleteFolderModal(!openDeleteFolderModal)
    setDeleteWoowFolderId(folderId)
  }

  const featureWoowHandler = (woowId, isFeatured) => {
    markWoowAsFeatured(woowId, isFeatured)
  }

  const markWoowAsFeatured = (woowId, isFeatured) => {
    const formData = new FormData()

    formData.append('_method', 'PATCH')
    formData.append('is_featured', +isFeatured)

    fetch(process.env.REACT_APP_API_URL + '/woows/' + woowId + '/mark-as-featured', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Update Woow failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      addToast('Woow marcado como destacado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const updateWoowHandler = woowId => {
    setOpenUpdateWoowModal(!openUpdateWoowModal)
    setSelectedWoowId(woowId)
  }

  const updateWoowTitle = event => {
    event.preventDefault()

    const introducedTitle = woowTitleInputRef.current.value
    const formData = new FormData()

    if (introducedTitle.length > 0) {
      formData.append('_method', 'PATCH')
      formData.append('title', introducedTitle)
    } else {
      setOpenUpdateWoowModal(!openUpdateWoowModal)
      return
    }

    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenUpdateWoowModal(!openUpdateWoowModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Update Woow failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      addToast('Woow renombrado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const deleteWoowHandler = woowId => {
    setSelectedWoowId(woowId)
    setOpenDeleteWoowModal(!openDeleteWoowModal)
  }

  const deleteWoow = () => {
    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId + '/delete', {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenDeleteWoowModal(!openDeleteWoowModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Delete consent failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      addToast('Documento eliminado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelAssignFolderHandler = () => {
    setOpenFolderModal(!openFolderModal)
  }

  const assignFolderHandler = (folder, woowId) => {
    setWoowFolder(folder)
    setSelectedWoowId(woowId)
    setOpenFolderModal(!openFolderModal)
  }

  const assignFolder = () => {
    const folderSelected = folderSelectorRef.current.value

    const formData = new FormData()

    formData.append('_method', 'PATCH')
    formData.append('woow_folder', folderSelected)

    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId + '/assign-folder', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenFolderModal(!openFolderModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Assign Woow failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      addToast('Woow asignado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const shareWoowHandler = uuid => {
    let domain = (new URL(document.location.href))

    setShareUrl(domain.protocol + '//' + domain.hostname + ':' + domain.port + '/woow/' + uuid)
    setOpenShareModal(!openShareModal)
  }

  return (
    <div className="items-center w-full pt-3 pb-4 gap-4">

      <div className="w-full">
        <p className={'mb-5'}>Añade tus destacados desde la pestaña WOOW en la ficha de un {props.customerStr}</p>
        <div className={'flex space-x-4'}>
          <ButtonWithLeadingIcon text={'Nueva carpeta'}
                                 leadingIcon={<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>}
                                 classes={'bg-blue-100 text-secondary-default px-7'} click={createWoowFolderHandler}/>
        </div>
        {checkIfWoowWithoutFolder() &&
        <Fragment>
          <p className={'font-lexendSemiBold text-2xl pt-5'}>
            Sin Categoria
          </p>
          <p className={'border-b-2 pb-2'}>
            Aquí se encuentran los WOOW destacados que no han sido asignados a una carpeta
          </p>
          <div className={'flex flex-wrap border-box mx-2'}>
            {companyCtx.company.woows.map(woow => {
              return woow.folder === null && woow.is_featured &&
                <WoowCard key={woow.id} woow={woow} delete={deleteWoowHandler} update={updateWoowHandler}
                          feature={featureWoowHandler} preview={previewWoowHandler} assignFolder={assignFolderHandler}
                          share={shareWoowHandler}/>
            })}
          </div>
        </Fragment>
        }
        {folders.map(folder => {

          return <ItemsGallery key={folder.id} item={folder} updateHandler={updateWoowFolderHandler}
                               deleteHandler={deleteWoowFolderHandler}>
            {folder.woows && folder.woows.map(woow => {
              return <WoowCard key={woow.id} woow={woow} delete={deleteWoowHandler} update={updateWoowHandler}
                               feature={featureWoowHandler} preview={previewWoowHandler}
                               assignFolder={assignFolderHandler} share={shareWoowHandler}/>
            })}
          </ItemsGallery>
        })}
      </div>
      {openCreateModal &&
      <TreatmentFormModal key={'modal'} open={openCreateModal} onCancel={cancelCreate}
                          titleRef={folderTitleInputRef} confirmLabel={'Crear'} dismissLabel={'Cancelar'}
                          descriptionRef={folderDescriptionInputRef} title={'Crear nueva carpeta'}
                          description={'Indique el título de la carpeta y una descripción'}
                          onAction={formSubmissionHandler} titleErrorMessage={errors.title}
                          descriptionErrorMessage={errors.description}/>
      }
      {openUpdateFolderModal &&
      <TreatmentFormModal key={'create-folder-modal'} open={openUpdateFolderModal} onCancel={cancelUpdate}
                          titleRef={folderTitleInputRef} confirmLabel={'Actualizar'} dismissLabel={'Cancelar'}
                          descriptionRef={folderDescriptionInputRef} title={'Actualizar carpeta'}
                          description={'Indique el nuevo título de la carpeta y una descripción'}
                          onAction={updateWoowFolder} previousTitleValue={folderTitle}
                          previousDescriptionValue={folderDescription} titleErrorMessage={errors.title}
                          descriptionErrorMessage={errors.description}/>
      }
      {openDeleteFolderModal &&
      <AlertModal key={'modal'} open={openDeleteFolderModal} onCancel={cancelDelete} title={'Eliminar carpeta'}
                  onDeleteAction={deleteWoowFolder} confirmLabel={'Eliminar'} dismissLabel={'Cancelar'}
                  description={'¿Estás seguro de eliminar esta carpeta?'}/>
      }
      {openUpdateWoowModal &&
      <ActionPanelWithInputModal key={'modal'} open={openUpdateWoowModal} onCancel={cancelUpdate}
                                 setRef={woowTitleInputRef}
                                 title={'Cambiar título del Woow'} confirmLabel={'Cambiar'} dismissLabel={'Cancelar'}
                                 onAction={updateWoowTitle} description={'Indique el nuevo título del Woow'}/>
      }
      {openDeleteWoowModal &&
      <AlertModal key={'modal'} open={openDeleteWoowModal} onCancel={cancelDelete} title={'Eliminar Woow'}
                  onDeleteAction={deleteWoow} confirmLabel={'Eliminar'} dismissLabel={'Cancelar'}
                  description={'¿Estás seguro de eliminar este Woow?'}/>
      }
      {openPreviewModal &&
      <WoowPreviewModal open={openPreviewModal} onCancel={previewWoowHandler} dismissLabel={'Cerrar'} woow={previewWoow}
                        logo={woowCompanyLogo} share={shareWoowHandler}/>
      }
      {openFolderModal &&
      <FolderSelectorModal open={openFolderModal} onCancel={cancelAssignFolderHandler} onSubmit={assignFolder}
                           confirmLabel={'Asignar'} dismissLabel={'Cancelar'} folders={companyCtx.company.woow_folders}
                           setRef={folderSelectorRef} title={'Asignación de carpeta'} defaultFolder={woowFolder}
                           description={'Seleccione una carpeta para categorizar el woow'}
                           folderLabel={'Carpetas disponibles'}/>
      }
      {openShareModal &&
      <ShareUrlModal open={openShareModal} onCancel={shareWoowHandler} dismissLabel={'Cerrar'} url={shareUrl}/>
      }
    </div>
  )
}

export default FeaturedWoow
