import AuthLayout from '../../components/Layout/Auth/AuthLayout'
import LoginForm from '../../components/Auth/login/LoginForm'

const LoginPage = () => {
  document.title = 'Inicio de sesión | WOOW'

  return (
    <AuthLayout>
      <LoginForm/>
    </AuthLayout>
  )
}

export default LoginPage
