export const capitalize = (str, pluralize = false) => {
  const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);

  if (pluralize) {
    return capitalizedStr + 's'
  }

  return capitalizedStr
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
