import BeforeAfterCard from '../BeforeAfter/BeforeAfterCard'
import { useContext, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import CompanyContext from '../../../store/company-context'
import AuthContext from '../../../store/auth-context'
import AlertModal from '../../UI/AlertModal'

const UserImages = props => {
  const {addToast} = useToasts()

  const companyCtx = useContext(CompanyContext)
  const authCtx = useContext(AuthContext)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState()

  const deleteImage = event => {
    event.preventDefault()

    fetch(process.env.REACT_APP_API_URL + '/images/' + selectedImageId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenDeleteModal(!openDeleteModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Delete image failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      props.user.woows = data.user_woows
      addToast('Imagen eliminado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const download = image => {
    fetch( process.env.REACT_APP_ASSET_URL + image.path, {
      responseType: 'blob', // important
    }).then((res) => {
      res.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', image.filename + '.jpeg')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  const cancelDelete = () => {
    setOpenDeleteModal(!openDeleteModal)
  }

  const deleteImageHandler = imageId => {
    setSelectedImageId(imageId)
    setOpenDeleteModal(!openDeleteModal)
  }

  return (
    <div className="items-center justify-center shadow-md bg-white w-full pt-6 pb-4 px-10">
      <div className="flex flex-wrap border-box mx-2">
        {props.user.images.map(image => {
          return <BeforeAfterCard key={image.id} image={image} delete={deleteImageHandler} download={download}/>
        })}
      </div>
      {openDeleteModal &&
      <AlertModal key={'modal'} open={openDeleteModal} onCancel={cancelDelete} title={'Eliminar Woow'}
                  onDeleteAction={deleteImage} confirmLabel={'Eliminar'} dismissLabel={'Cancelar'}
                  description={'¿Estás seguro de eliminar esta Imagen?'}/>
      }
    </div>
  )
}

export default UserImages
