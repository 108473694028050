import { Fragment, useEffect, useRef } from 'react'

const SearchInputWithLeadingIcon = (props) => {
  const searchInputRef = useRef()

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        const enteredSearch = searchInputRef.current.value

        props.onSearch(enteredSearch)
      }
    }

    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [props])

  return (
    <Fragment>
      <div className={'relative rounded-md shadow-sm my-3 ' + props.width}>
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {props.leadingIcon}
        </div>
        <input
          type="text"
          name="email"
          id="email"
          className="focus:ring-primary-default focus:border-primary-default block w-full pl-10 border-none rounded-md bg-gray-50 h-full "
          placeholder={props.placeholder}
          ref={searchInputRef}
        />
      </div>
    </Fragment>
  )
}

export default SearchInputWithLeadingIcon
