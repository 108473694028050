import RecoveryPasswordForm from '../../components/Auth/password/RecoveryPasswordForm'
import AuthLayout from '../../components/Layout/Auth/AuthLayout'

const RecoveryPasswordPage = () => {
  document.title = 'Recuperar contraseña | WOOW'

  return (
    <AuthLayout>
      <RecoveryPasswordForm/>
    </AuthLayout>
  )
}

export default RecoveryPasswordPage
