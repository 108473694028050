import NewUserForm from '../../components/Company/NewUserForm'
import { capitalize } from '../../components/Helpers/utils'
import Container from '../../components/Layout/Container'
import CompanyContext from '../../store/company-context'
import { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'

const CompanyNewUserPage = () => {
  const companyCtx = useContext(CompanyContext)
  const userStr = capitalize(companyCtx.customer, true)

  document.title = 'Nuevo ' + userStr + ' | WOOW'

  const [appLogo, setAppLogo] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  useEffect(() => {
    companyCtx.company.logos.forEach(logo => {
      switch (logo.type) {
        case 'company':
          setCompanyLogo(logo.path)
          break
        case 'app':
          setAppLogo(logo.path)
          break
        default:
          break
      }
    })
  }, [companyCtx.company.logos])

  return (
    <Layout customerStr={userStr} isAdmin={companyCtx.isAdmin} name={companyCtx.company.name} companyLogo={companyLogo}
            appLogo={appLogo}>
      <Container header={{title: 'Nuevo usuario', classes: 'font-lexendSemiBold text-4xl'}}
                 subtitle={{text: 'Para crear un nuevo ' + companyCtx.customer +' rellena los siguientes datos:', classes: 'mt-2'}}
                 containerClasses={'flex flex-wrap flex-col bg-primary-default text-white w-full p-5 rounded-t-lg'}
                 classes={'py-6 max-w-[calc(100%+1rem)] mx-7 px-4 sm:px-6 md:px-8'}>
        <div className="md:flex w-full justify-between">
          <NewUserForm/>
        </div>
      </Container>
    </Layout>
  )
}

export default CompanyNewUserPage
