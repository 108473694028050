import { useToasts } from 'react-toast-notifications'
import InputWithError from '../../UI/InputWithError'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { Link, useHistory } from 'react-router-dom'
import { Fragment, useRef, useState } from 'react'

const RegisterForm = () => {
  const {addToast} = useToasts()

  const emailInputRef = useRef()
  const firstNameInputRef = useRef()
  const lastNameInputRef = useRef()
  const nameInputRef = useRef()
  const typeSelectorRef = useRef()
  const profileSelectorRef = useRef()
  const phoneInputRef = useRef()
  const passwordInputRef = useRef()
  const passwordConfirmationInputRef = useRef()
  const consentConfirmationCheckboxRef = useRef()

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: '',
    first_name: '',
    last_name: '',
    name: '',
    type: '',
    profile: '',
    phone: '',
    password: '',
    password_confirmation: '',
    terms: ''
  })

  const submitHandler = (event) => {
    event.preventDefault()

    const enteredEmail = emailInputRef.current.value
    const enteredFirstName = firstNameInputRef.current.value
    const enteredLastName = lastNameInputRef.current.value
    const enteredName = nameInputRef.current.value
    const selectedType = typeSelectorRef.current.value
    const selectedProfile = profileSelectorRef.current.value
    const enteredPhone = phoneInputRef.current.value
    const enteredPassword = passwordInputRef.current.value
    const enteredPasswordConfirmation = passwordConfirmationInputRef.current.value
    const selectedConsentConfirmation = consentConfirmationCheckboxRef.current.value

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/register', {
      method: 'POST',
      body: JSON.stringify({
        email: enteredEmail,
        first_name: enteredFirstName,
        last_name: enteredLastName,
        name: enteredName,
        type: selectedType,
        profile: selectedProfile,
        phone: enteredPhone,
        password: enteredPassword,
        password_confirmation: enteredPasswordConfirmation,
        terms: selectedConsentConfirmation
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        email: '',
        name: '',
        type: '',
        profile: '',
        phone: '',
        password: '',
        password_confirmation: '',
        terms: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then(() => {
      addToast('Se ha mandado un correo de verificación. Por favor revise su bandeja de entrada', {
        appearance: 'success',
        autoDismiss: true,
      })
      history.replace('/')
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          email: err.errors.email && err.errors.email[0],
          name: err.errors.name && err.errors.name[0],
          type: err.errors.type && err.errors.type[0],
          profile: err.errors.profile && err.errors.profile[0],
          phone: err.errors.phone && err.errors.phone[0],
          password: err.errors.password && err.errors.password[0],
          password_confirmation: err.errors.password_confirmation && err.errors.password_confirmation[0],
          terms: err.errors.terms && err.errors.terms[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else if (err.error_msg) {
        setErrors({email: 'Compruebe que es un email válido y puede recibir correos'})
        addToast('No se ha podido enviar el correo de confirmación. Por favor revise la dirección introducida', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  return (
    <Fragment>
      <div className="max-w-screen px-4 py-2 lg:py-16 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto text-center">
          <p className="text-sm">
            ¿Ya tienes cuenta? <Link to="/login" className="font-bold text-primary-default">Inicia sesión con tus
            datos</Link>
          </p>
          <p className="text-3xl font-lexendSemiBold sm:text-4xl">Crea tu cuenta gratuita</p>
          <p className="font-lexendMedium">Con una validez de 30 días</p>
        </div>
        <form onSubmit={submitHandler} className="max-w-xl mx-auto mt-8 mb-0 space-y-2">
          <div className="relative">
            <InputWithError label={'Email:'} type={'email'} name={'email'} id={'email-input'} required={true}
                            setRef={emailInputRef} placeholder={'johndoe@email.com'} errorMessage={errors.email}
                            description={'email'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Nombre:'} type={'text'} name={'first-name'} id={'first-name-input'} required={true}
                            setRef={firstNameInputRef} placeholder={'John'} errorMessage={errors.first_name}
                            description={'Campo para el nombre'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Apellidos:'} type={'text'} name={'last-name'} id={'last-name-input'} required={true}
                            setRef={lastNameInputRef} placeholder={'Doe'} errorMessage={errors.last_anme}
                            description={'Campo para los apellidos'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Nombre de tu empresa:'} type={'text'} name={'name'} id={'name-input'}
                            required={true}
                            setRef={nameInputRef} placeholder={'Clínica Sol'} errorMessage={errors.name}
                            description={'Campo para el nombre de la empresa'}/>
          </div>
          <div className="relative">
            <label htmlFor="type" className="font-bold">Sector de tu negocio: <span
              style={{'color': 'red'}}>*</span></label>
            <select className="w-full p-4 text-sm bg-gray-50 rounded-lg shadow-sm border-none" ref={typeSelectorRef}
                    required>
              <option value="">Seleccione una opción</option>
              <option value="medical">Medicina estética</option>
              <option value="dental">Odontología</option>
              <option value="hairdresser">Peluquería</option>
              <option value="design">Interiorismo</option>
              <option value="architecture">Arquitectura</option>
            </select>
          </div>
          <div className="relative">
            <InputWithError label={'Profesión:'} type={'text'} name={'name'} id={'name-input'}
                            required={true}
                            setRef={profileSelectorRef} placeholder={'Manager'} errorMessage={errors.profile}
                            description={'Puesto en la empresa'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Teléfono:'} type={'text'} name={'phone'} id={'phone-input'}
                            required={true}
                            setRef={phoneInputRef} placeholder={'600111222'} errorMessage={errors.phone}
                            description={'Campo para el teléfono de la empresa'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Contraseña:'} type={'password'} name={'password'} id={'password-input'}
                            required={true}
                            setRef={passwordInputRef} placeholder={'***********'} errorMessage={errors.password}
                            description={'Campo para contraseña'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Repetir contraseña:'} type={'password'} name={'password_confirm'}
                            id={'password_confirm-input'} required={true}
                            setRef={passwordConfirmationInputRef} placeholder={'***********'}
                            errorMessage={errors.password_confirmation}
                            description={'Campo para confirmación de contraseña'}/>
          </div>
          <div className="relative">
            <label className="flex items-center space-x-3">
              <input type="checkbox" name="checked-demo" value="1"
                     className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                     ref={consentConfirmationCheckboxRef} required/>
              <span className="text-sm font-lexendLight">Acepto los <a href={'https://woowphoto.com/aviso-legal/'}
                                                                       className={'underline'}>Términos y condiciones</a> y la <a
                href={'https://woowphoto.com/politica-de-privacidad/'}
                className={'underline'}>Política de privacidad</a> de WOOW.</span>
            </label>
          </div>
          {!isLoading && (
            <div className="flex items-center justify-center">
              <button type="submit"
                      className="inline-block my-9 px-20 py-2.5 text-white bg-primary-default rounded-lg font-bold">
                Crear cuenta
              </button>
            </div>
          )}
          {isLoading && (
            <LoadingSpinner/>
          )}
        </form>
      </div>
    </Fragment>
  )
}

export default RegisterForm
