const ContainerTitle = (props) => {
  return (
    <div className={props.containerClasses || 'flex items-center'}>
      {!!props.withAvatar && (
        <span className="flex h-24 w-24 rounded-full overflow-hidden bg-gray-100 items-center mr-3">
          {!!props.userLogo && props.userLogo.length > 0 && (
            <img className="m-auto h-full max-w-none" src={process.env.REACT_APP_ASSET_URL + props.userLogo}
                 alt={'Company Logo'}/>
          )}
          {!props.userLogo && (
            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
            </svg>
          )}
        </span>
      )}
      <p className={props.classes}>{props.title}</p>
      {props.subtitle &&
      <p className={props.subtitle.classes}>{props.subtitle.text}</p>
      }
    </div>
  )
}

export default ContainerTitle
