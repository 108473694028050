import React, { useRef } from 'react'
import ModalTemplate from '../../UI/ModalTemplate'
import { ClipboardCopyIcon, ShareIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { useToasts } from 'react-toast-notifications'

const ShareUrlModal = (props) => {
  const { addToast } = useToasts()
  const cancelButtonRef = useRef()

  const copy = async () => {
    await navigator.clipboard.writeText(props.url);
    addToast('¡Dirección copiada en el portapapeles!', {appearance: 'success', autoDismiss: true})
    props.onCancel()
  }

  return (
    <ModalTemplate open={props.open} setRef={props.cancelButtonRef} onCancel={props.onCancel}>
      <div
        className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
        <div className="mt-3 text-center sm:mt-0 mb-4 sm:text-left">
          <Dialog.Title as="h3" className="text-2xl font-bold leading-6">
            Compartir Woow
          </Dialog.Title>
          <label htmlFor="link" className="block text-sm font-medium text-gray-700 mt-5">
            URL
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <ShareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </div>
              <input
                type="text"
                name="link"
                id="link"
                className="focus:ring-primary-default focus:border-primary-default block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                placeholder="John Doe"
                defaultValue={props.url}
                readOnly={true}
              />
            </div>
            <button
              className="-ml-px relative bg-primary-default inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 focus:outline-none focus:ring-1 focus:ring-primary-default focus:border-primary-default"
              onClick={copy}>
              <ClipboardCopyIcon className="h-5 w-5 text-white font-semibold" aria-hidden="true"/>
              <span className={'text-white font-semibold'}>Copiar</span>
            </button>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={props.onCancel}
            ref={cancelButtonRef}
          >
            {props.dismissLabel}
          </button>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ShareUrlModal
