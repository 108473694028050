import { Menu, Transition } from '@headlessui/react'
import { classNames } from '../Helpers/utils'
import { Fragment } from 'react'

const ItemsGallery = props => {

  return (
    <Fragment key={props.item.id}>
      <p className={'font-lexendSemiBold text-2xl pt-5'}>
        {props.item.title}
      </p>
      <p className={'border-b-2 pb-2'}>
        {props.item.description}
      </p>
      <Menu as="span" className="ml-3 float-right relative bottom-10">
        {({open}) => (
          <Fragment>
            <div>
              <Menu.Button
                className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
                </svg>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none right-4">
                <Menu.Item>
                  {({active}) => (
                    <p className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                       onClick={() => props.updateHandler(props.item.id, props.item.title, props.item.description)}>
                      Editar
                    </p>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({active}) => (
                    <p className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                       onClick={() => props.deleteHandler(props.item.id)}>
                      Eliminar
                    </p>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Fragment>
        )}
      </Menu>
      <div className={'flex flex-wrap space-x-2 justify-center md:justify-start'}>
        {props.children}
      </div>
    </Fragment>
  )
}

export default ItemsGallery
