import ButtonWithLeadingIcon from '../../UI/ButtonWithLeadingIcon'
import { useContext, useEffect, useRef, useState } from 'react'
import CompanyContext from '../../../store/company-context'
import AuthContext from '../../../store/auth-context'
import { useToasts } from 'react-toast-notifications'
import InputWithError from '../../UI/InputWithError'
import LoadingSpinner from '../../UI/LoadingSpinner'
import FileInput from '../../UI/FileInput'

const InformationProfile = (props) => {
  const {addToast} = useToasts()

  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)

  const companyNameRef = useRef(companyCtx.company.name)

  console.log(companyCtx.company)
  const managerFirstNameRef = useRef(companyCtx.company.manager_first_name)
  const managerLastNameRef = useRef(companyCtx.company.manager_last_name)
  const companyLogoRef = useRef()
  const baseLogoRef = useRef()
  const whiteLogoRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({company_name: '', manager_first_name: '', manager_last_name: ''})
  const [companyImg, setCompanyImg] = useState()
  const [woowImg, setWoowImg] = useState()
  const [appImg, setAppImg] = useState()

  useEffect(() => {
    companyCtx.company.logos.forEach(logo => {
      switch (logo.type) {
        case 'company':
          setCompanyImg(logo)
          break
        case 'woow':
          setWoowImg(logo)
          break
        case 'app':
          setAppImg(logo)
          break
        default:
          break
      }
    })
  }, [companyCtx.company.logos])

  const formSubmissionHandler = (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('_method', 'PATCH')
    formData.append('company_name', companyNameRef.current.value)
    formData.append('manager_first_name', managerFirstNameRef.current.value)
    formData.append('manager_last_name', managerLastNameRef.current.value)

    companyLogoRef.current.files.length > 0 &&
    formData.append('company_logo', companyLogoRef.current.files[0], companyLogoRef.current.files[0].name)

    baseLogoRef.current.files.length > 0 &&
    formData.append('woow_logo', baseLogoRef.current.files[0], baseLogoRef.current.files[0].name)

    whiteLogoRef.current.files.length > 0 &&
    formData.append('app_logo', whiteLogoRef.current.files[0], whiteLogoRef.current.files[0].name)

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/companies/' + companyCtx.company.id, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        manager_first_name: '',
        manager_last_name: '',
        company_name: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.data)
      props.updateLogosHandler()
      addToast('Datos actualizados', {appearance: 'success', autoDismiss: true})
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          company_name: err.errors.company_name && err.errors.company_name[0],
          manager_first_name: err.errors.manager_first_name && err.errors.manager_first_name[0],
          manager_last_name: err.errors.manager_last_name && err.errors.manager_last_name[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  const removeImageHandler = (logo) => {
    fetch(process.env.REACT_APP_API_URL + '/companies/' + companyCtx.company.id + '/logo/' + logo.id + '/delete', {
      method: 'DELETE',
      body: JSON.stringify({
        logo: logo
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.data)
      props.updateLogosHandler()
      window.location.reload(false)
      addToast('Imagen eliminada', {appearance: 'success', autoDismiss: true})
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          company_name: err.errors.company_name && err.errors.company_name[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  return (
    <form onSubmit={formSubmissionHandler} encType={'multipart/form-data'}>
      <div className="flex items-center justify-center px-10 border-b">
        <div className="bg-white w-full">
          <div
            className="flex flex-wrap md:grid md:grid-cols-3 md:space-y-0 space-y-1 mt-1 flex pt-6 items-center justify-center">
            <span className="flex h-24 w-24 rounded-full overflow-hidden bg-gray-100 items-center">
              {!props.companyLogo && (
                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                </svg>
              )}
              {props.companyLogo && (
                <img className="m-auto h-full max-w-none" src={process.env.REACT_APP_ASSET_URL + props.companyLogo}
                     alt={'Company Logo'}/>
              )}
            </span>
            <FileInput key={'company-logo'} name={'company-logo'} accept={'image/*'}
                       btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex sm:ml-5 md:ml-0'}
                       withoutFileName={false} btnText={'Añadir foto'} ref={companyLogoRef}
                       filename={companyCtx.companyLogo.filename}/>
            {companyImg &&
            <>
              <p className={'text-secondary-default cursor-pointer mt-3'}
                 onClick={() => removeImageHandler(companyImg)}>
                <small> Eliminar imagen actual</small>
              </p>
            </>
            }
          </div>
          <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
            <p className={'font-lexendSemiBold text-lg'}>Nombre:</p>
            <InputWithError label={''} type={'text'} name={'manager_first_name'} id={'manager-first-name-input'}
                            required={true} default={companyCtx.company.manager_first_name}
                            setRef={managerFirstNameRef} placeholder={'John'}
                            errorMessage={errors.manager_first_name}
                            description={'nombre'}/>
          </div>
          <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
            <p className={'font-lexendSemiBold text-lg'}>Apellidos:</p>
            <InputWithError label={''} type={'text'} name={'manager_last_name'} id={'manager-last-name-input'}
                            required={true} default={companyCtx.company.manager_last_name}
                            setRef={managerLastNameRef} placeholder={'Doe'}
                            errorMessage={errors.manager_last_name}
                            description={'apellidos'}/>
          </div>
          <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
            <p className={'font-lexendSemiBold text-lg'}>Nombre de la empresa:</p>
            <InputWithError label={''} type={'text'} name={'company_name'} id={'company-name-input'}
                            required={true} default={companyCtx.company.name}
                            setRef={companyNameRef} placeholder={'Clínica Sol'}
                            errorMessage={errors.company_name}
                            description={'nombre de la compañía'}/>
          </div>
          <div className={'border-b-2'}>
            <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 py-6 items-center">
              <p className={'font-lexendSemiBold text-lg'}>Email:</p>
              <p>{companyCtx.company.email || 'email@email.com'}</p>
            </div>
          </div>
          <div className={'border-b-2'}>
            <div className="pt-6 pb-4 items-center">

              <p className="font-lexendSemiBold text-xl">Firma tus WOOW</p>
              <p>Puedes añadir una marca de agua para tus WOOW con tu propio logotipo</p>
              <FileInput key={'woow-logo'} name={'woow-logo'} filename={companyCtx.woowLogo.filename} accept={'image/*'}
                         btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex'}
                         btnText={'Logotipo'} ref={baseLogoRef}/>
              {woowImg &&
              <>
                <p className={'text-secondary-default cursor-pointer'}
                   onClick={() => removeImageHandler(woowImg)}>
                  <small> Eliminar imagen actual</small>
                </p>
              </>
              }
            </div>
          </div>
          <div className="pt-6 pb-4 items-center">
            <p className="font-lexendSemiBold text-xl">Personaliza nuestra herramienta con tu logotipo</p>
            <p>Puedes personalizar la herramienta con tu propia imagen para que se muestre en el menú</p>
            <FileInput key={'app-logo'} name={'app-logo'} filename={companyCtx.appLogo.filename} accept={'image/*'}
                       btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex'}
                       btnText={'Logotipo color blanco'} ref={whiteLogoRef}/>
            {appImg &&
            <>
              <p className={'text-secondary-default cursor-pointer'}
                 onClick={() => removeImageHandler(appImg)}>
                <small> Eliminar imagen actual</small>
              </p>
            </>
            }
          </div>
        </div>
      </div>
      <div className={'flex items-center shadow-md px-10 border-t py-6 px-8'}>
        {!isLoading && <ButtonWithLeadingIcon text={'Actualizar información personal'} leadingIcon={''}
                                              classes={'bg-primary-default text-white px-12 hover:text-primary-default'}
                                              click={formSubmissionHandler}/>}
        {isLoading && <LoadingSpinner/>}
      </div>
    </form>
  )
}

export default InformationProfile
