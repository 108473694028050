import { StarIcon } from '@heroicons/react/outline'
import ModalTemplate from './ModalTemplate'
import { Dialog } from '@headlessui/react'
import { useRef } from 'react'

const TreatmentFormModal = (props) => {
  const cancelButtonRef = useRef()

  return (
    <ModalTemplate open={props.open} setRef={props.setRef} onCancel={props.onCancel}>
      <div
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div
            className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
            <StarIcon className="h-6 w-6 text-indigo-600" aria-hidden="true"/>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {props.title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {props.description}
              </p>
            </div>
            <div className="w-full">
              <label htmlFor="title" className="">
                Título<span style={{'color': 'red'}}>*</span>
              </label>
              <input
                type="text"
                name="title"
                id="title"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Título"
                ref={props.titleRef}
                required={true}
                defaultValue={props.previousTitleValue || ''}
              />
            </div>
            {props.titleErrorMessage && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {props.titleErrorMessage}
              </p>
            )}
            <div className="w-full mt-2">
              <label htmlFor="description" className="">
                Descripción
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Descripción"
                ref={props.descriptionRef}
                required={true}
                defaultValue={props.previousDescriptionValue}
              />
            </div>
            {props.descriptionErrorMessage && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {props.titleErrorMessage}
              </p>
            )}
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={props.onAction}
              >
                {props.confirmLabel}
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={props.onCancel}
                ref={cancelButtonRef}
              >
                {props.dismissLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default TreatmentFormModal
