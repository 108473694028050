import { LogoutIcon, MenuIcon, UsersIcon, XIcon } from '@heroicons/react/outline'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { matchPath, NavLink } from 'react-router-dom'
import { classNames } from '../Helpers/utils'
import { Fragment, useState } from 'react'

export default function Layout (props) {
  const getExternalUrl = type => {
    switch (type) {
      case 'medical':
        return 'https://woowphoto.com/medicina-estetica/'
      case 'dental':
        return 'https://woowphoto.com/clinicas-odontologicas/'
      case 'hairdresser':
        return 'https://woowphoto.com/salon-estetica-peluqueria/'
      case 'design':
        return 'https://woowphoto.com/interiorismo/'
      case 'architecture':
        return 'https://woowphoto.com/arquitectura/'
      default:
        return 'https://woowphoto.com'
    }
  }

  const navigationElements = props.isAdmin
    ? [
      {
        name: props.customerStr, href: '/', icon: UsersIcon, current: !!matchPath(window.location.pathname, {
          path: '/',
          exact: true,
          strict: false
        })
      },
      {name: 'Cerrar sesión', href: '/logout', icon: LogoutIcon},
    ]
    : [
      {
        name: props.customerStr, href: '/', icon: UsersIcon, current: !!matchPath(window.location.pathname, {
          path: '/',
          exact: true,
          strict: false
        }) || !!matchPath(window.location.pathname, {
          path: '/user/:userId',
          exact: true,
          strict: false
        })
      },
      {
        name: 'WOOW Destacados',
        href: '/featured-woows',
        icon: UsersIcon,
        current: !!matchPath(window.location.pathname, {
          path: '/featured-woows',
          exact: true,
          strict: false
        })
      },
      {
        name: 'Mi perfil', href: '/profile', icon: UsersIcon, current: !!matchPath(window.location.pathname, {
          path: '/profile',
          exact: true,
          strict: false
        })
      },
      {
        name: 'Blog', href: '', icon: UsersIcon, current: null
      },
      {name: 'Cerrar sesión', href: '/logout', icon: LogoutIcon},
    ]

  const userNavigation = [
    {name: 'Perfil', href: '/profile'},
  ]

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [navigation, setNavigation] = useState(navigationElements)

  const navigationHandler = element => {
    if (element.name === 'Blog') {
      let url = ''

      switch (props.companyType) {
        case 'medical':
          url = 'https://woowphoto.com/category/medicina-estetica/'
          break
        case 'dental':
          url = 'https://woowphoto.com/category/odontologia/'
          break
        case 'hairdresser':
          url = 'https://woowphoto.com/category/peluqueria/'
          break
        case 'design':
          url = 'https://woowphoto.com/category/interiorismo/'
          break
        case 'architecture':
          url = 'https://woowphoto.com/category/arquitectura/'
          break
        default:
          url = 'https://woowphoto.com/blog'
      }
      window.open(url, '_blank')
    }

    const updatedNavigation = navigation.map(item => {
      if (item.current === true && item.href !== element.href) {
        return {...item, current: false}
      } else if (item.current === false && item.href === element.href) {
        return {...item, current: true}
      }

      return item
    })

    setNavigation(updatedNavigation)
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div
              className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gradient-to-b from-primary-default to-secondary-default text-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Cerrar sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center flex-shrink-0 px-4 m-auto">
                {props.companyLogo && (
                  <img className="h-10 w-auto mb-5" src={process.env.REACT_APP_ASSET_URL + props.companyLogo}
                       alt={'Company Logo'}/>
                )}
              </div>
              <div className="flex items-center flex-shrink-0 px-4 m-auto">
                <a href={getExternalUrl(props.companyType)} target={'_blank'} rel={'noopener noreferrer'}>
                  <img
                    className="h-10 w-auto block"
                    src={process.env.REACT_APP_CALLBACK + '/images/logo-woow.svg'}
                    alt="WOOW Logo"
                  />
                </a>
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="flex-1 px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-white text-primary-default' : 'text-default-primary hover:bg-purple-default',
                        'group flex items-center px-2 py-2 font-bold rounded-md'
                      )}
                      onClick={() => navigationHandler(item)}>
                      {item.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className="hidden bg-gradient-to-b from-primary-default to-secondary-default text-white md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-1 py-1 m-auto">
              {props.appLogo && (
                <img className="h-10 w-auto mb-5" src={process.env.REACT_APP_ASSET_URL + props.appLogo}
                     alt={'Company Logo'}/>
              )}
            </div>
            <div className="flex items-center flex-shrink-0 px-1 py-1 m-auto">
              <a href={getExternalUrl(props.companyType)} target={'_blank'} rel={'noopener noreferrer'}>
                <img
                  className="h-10 w-auto"
                  src={process.env.REACT_APP_CALLBACK + '/images/logo-woow.svg'}
                  alt="WOOW logo"/>
              </a>
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-white text-primary-default' : 'text-default-primary hover:bg-purple-default',
                      'group flex items-center px-2 py-2 font-bold rounded-md'
                    )}
                    onClick={() => navigationHandler(item)}>
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16">
          <button
            type="button"
            className="px-4 text-dark-default focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-default md:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Abrir sidebar</span>
            <MenuIcon className="h-7 w-7" aria-hidden="true"/>
          </button>
          <div className="flex-1 px-4 flex justify-between bg-gray-100">
            <div className="flex-1 flex">
            </div>
            <div className="ml-4 flex items-center md:ml-6">

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                {({open}) => (
                  <Fragment>
                    <div>
                      <Menu.Button
                        className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="px-4 font-lexendSemiBold text-secondary-default">{props.name}</span>
                        <span className="h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                          {props.companyLogo && (
                            <img className="h-8 w-auto"
                                 src={process.env.REACT_APP_ASSET_URL + props.companyLogo}
                                 alt={'Company Logo'}/>
                          )}
                          {!props.companyLogo && (
                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                              <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                          )}
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({active}) => (
                              <NavLink
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}>
                                {item.name}
                              </NavLink>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Fragment>
                )}
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {props.children}
        </main>
      </div>
    </div>
  )
}
