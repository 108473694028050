import ResetPasswordProfileForm from '../../components/Company/Profile/ResetPasswordProfileForm'
import InformationProfile from '../../components/Company/Profile/InformationProfile'
import PlanProfile from '../../components/Company/Profile/PlanProfile'
import { useCallback, useContext, useEffect, useState } from 'react'
import { capitalize } from '../../components/Helpers/utils'
import Container from '../../components/Layout/Container'
import CompanyContext from '../../store/company-context'
import Layout from '../../components/Layout/Layout'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Tabs from '../../components/UI/Tabs'

const CompanyProfilePage = () => {
  document.title = 'Mi perfil | WOOW'

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

  const companyCtx = useContext(CompanyContext)

  const userStr = capitalize(companyCtx.customer, true)

  const [tabs, setTabs] = useState([
    {name: 'Información personal', href: '#info', current: true},
    {name: 'Plan actual', href: '#plan', current: false},
    {name: 'Actualizar contraseña', href: '#password', current: false}
  ])
  const [appLogo, setAppLogo] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  const updateLogos = useCallback(() => {
    companyCtx.company.logos.forEach(logo => {
      switch (logo.type) {
        case 'company':
          setCompanyLogo(logo.path)
          break
        case 'app':
          setAppLogo(logo.path)
          break
        default:
          break
      }
    })
  }, [companyCtx.company.logos])

  useEffect(() => {
    updateLogos()
  }, [updateLogos])

  const tabSelectedHandler = useCallback((tab) => {
    const updatedTabs = tabs.map(element => {
      if (typeof tab === 'string') {
        tab = JSON.parse(tab)
      }

      if (element.current === true && element.href !== tab.href) {
        return {...element, current: false}
      } else if (element.current === false && element.href === tab.href) {
        return {...element, current: true}
      }

      return element
    })

    setTabs(updatedTabs)
  }, [tabs])

  return (
    <Layout customerStr={userStr} isAdmin={companyCtx.isAdmin} name={companyCtx.company.name} companyLogo={companyLogo}
            appLogo={appLogo}>
      <Container header={{title: 'Mi perfil', classes: 'font-lexendSemiBold text-4xl'}}
                 classes={'py-6 max-w-[calc(100%+1rem)] mx-7 px-4 sm:px-6 md:px-8'}>
        <Tabs tabs={tabs} selectedTab={tabSelectedHandler}>
          {tabs.map((tab, index) => {
            if (tab.current && tab.href === '#info') {
              return <InformationProfile key={index + '_information'} updateLogosHandler={updateLogos} companyLogo={companyLogo}/>
            } else if (tab.current && tab.href === '#plan') {
              return (
                <Elements stripe={stripePromise}>
                  <PlanProfile key={index + '_plans'}/>
                </Elements>
              )
            } else if (tab.current && tab.href === '#password') {
              return <ResetPasswordProfileForm key={index + '_reset_password'}/>
            }

            return false
          })}
        </Tabs>
      </Container>
    </Layout>
  )
}

export default CompanyProfilePage
