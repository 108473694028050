import { Dialog, Transition } from '@headlessui/react'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from '../../UI/LoadingSpinner'
import InputWithError from '../../UI/InputWithError'
import ReactCompareImage from 'react-compare-image'
import { Fragment, useRef, useState } from 'react'
import { classNames } from '../../Helpers/utils'
import Image from 'react-image-resizer'

const WoowGeneratorModal = props => {
  const {addToast} = useToasts()

  const treatmentSelectorRef = useRef()
  const verticalCheckboxRef = useRef()
  const cancelButtonRef = useRef()
  const titleInputRef = useRef()

  const [secondImageSelected, setSecondImageSelected] = useState()
  const [firstImageSelected, setFirstImageSelected] = useState()
  const [treatmentSelected, setTreatmentSelected] = useState()
  const [showPreview, setShowPreview] = useState(false)
  const [woowPreview, setWoowPreview] = useState({
    title: '',
    before_photo: null,
    after_photo: null,
    is_vertical: false
  })
  const [errors, setErrors] = useState({
    woow_title: '',
  })

  const updateTreatmentSelected = () => {
    const selectedTreatment = treatmentSelectorRef.current.value

    if (selectedTreatment) {
      const finalTreatment = props.treatments.find(treatment => {
        return treatment.id === +selectedTreatment
      })

      setTreatmentSelected(finalTreatment)
    } else {
      setTreatmentSelected(null)
      setFirstImageSelected(null)
      setSecondImageSelected(null)
    }
  }

  const firstImageSelectedHandler = photo => {
    setSecondImageSelected(null)
    setFirstImageSelected(photo)
  }

  const secondImageSelectedHandler = photo => {
    setSecondImageSelected(photo)
  }

  const generatePreview = () => {
    const enteredTitleValue = titleInputRef.current.value
    if (enteredTitleValue === '' || !firstImageSelected || !secondImageSelected) {
      if (enteredTitleValue === '') {
        errors.woow_title = 'Este campo es obligatorio'
      }
      addToast('Faltan datos necesarios', {appearance: 'error', autoDismiss: true})
      return
    }
    const woowPreviewObj = {
      title: titleInputRef.current.value,
      before_photo: firstImageSelected,
      after_photo: secondImageSelected,
      is_vertical: verticalCheckboxRef.current.checked
    }

    setErrors({
      woow_title: ''
    })

    setWoowPreview(woowPreviewObj)
    setShowPreview(true)
  }

  const backToForm = () => {
    setShowPreview(false)
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={props.setRef}
        open={props.open}
        onClose={props.onCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-900 bg-opacity-95 transition-opacity"/>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-4xl w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                  {!showPreview &&
                  <Fragment>
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium">
                      {props.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-3">
                        {props.description}
                      </p>
                    </div>
                  </Fragment>
                  }
                  <div className="w-full">
                    {!showPreview &&
                    <Fragment>
                      <div className={'relative'}>
                        <label htmlFor="treatment" className="font-bold">
                          {props.treatmentLabel}<span style={{'color': 'red'}}>*</span>
                        </label>
                        <select
                          id="treatment"
                          name="treatment"
                          autoComplete="treatment"
                          onChange={updateTreatmentSelected}
                          ref={treatmentSelectorRef}
                          defaultValue={treatmentSelected && treatmentSelected.id}
                          className="w-full p-4 text-sm bg-gray-50 rounded-lg shadow-sm border-none mb-3"
                        >
                          <option value={false}>Selecciona {props.treatmentStr === 'obra' ? 'una' : 'un'} {props.treatmentStr}</option>
                          {props.treatments.map(treatment => {
                            return <option value={treatment.id}>{treatment.title}</option>
                          })}
                        </select>
                      </div>
                      {treatmentSelected && (
                        <div className="relative">

                          <InputWithError label={'Título'} type={'text'} name={'title'} id={'title'} required={true}
                                          description={'Título del WOOW'}
                                          setRef={titleInputRef} placeholder={'Título del WOOW'}
                                          errorMessage={errors.woow_title} default={woowPreview.title}/>
                        </div>
                      )}
                      {treatmentSelected && treatmentSelected.photos.length < 2 && (
                        <label htmlFor="photos" className={'block text-red-600 font-bold text-center mt-4'}>
                          Debes tener al menos dos fotos {props.treatmentStr === 'obra' ? 'en la' : 'en el'} {props.treatmentStr}
                        </label>
                      )}
                      {treatmentSelected && treatmentSelected.photos.length >= 2 && (
                        <Fragment>
                          <label htmlFor="photos" className={'block text-sm text-gray-700 mt-4'}>
                            Selecciona la foto del Antes
                          </label>
                          <div className={'flex flex-wrap space-x-2 justify-center md:justify-start'}>
                            {treatmentSelected && treatmentSelected.photos && treatmentSelected.photos.map(photo => {
                              return <div key={photo.id + '_before'}
                                          onClick={() => firstImageSelectedHandler(photo)}
                                          className={classNames(
                                            photo === firstImageSelected ? 'ring-4 ring-indigo-500' : '',
                                            'border rounded-md my-1 hover:border-primary-default pt-1.5')}>
                                {photo && (
                                  <Image key={photo.id} height={64} width={64}
                                         src={process.env.REACT_APP_ASSET_URL + photo.path}
                                         alt={photo.filename} noImageAlt={'Image not found'} style={{
                                    'margin': '0 auto',
                                    'height': '11rem',
                                    'width': '11rem',
                                    'background': '#eef2ff',
                                    'borderRadius': '0.375rem'
                                  }}/>
                                )}
                              </div>
                            })}
                          </div>
                        </Fragment>
                      )}
                      {firstImageSelected && (
                        <label htmlFor="photos" className={'block text-sm text-gray-700 mt-4'}>
                          Selecciona la foto del Después
                        </label>
                      )}
                      <div className={'flex flex-wrap space-x-2 justify-center md:justify-start'}>
                        {firstImageSelected && treatmentSelected.photos && treatmentSelected.photos.map(photo => {
                          return photo !== firstImageSelected &&
                            <div key={photo.id + '_after'}
                                 onClick={() => secondImageSelectedHandler(photo)}
                                 className={classNames(
                                   photo === secondImageSelected ? 'ring-4 ring-indigo-500' : '',
                                   'border rounded-md my-1 hover:border-primary-default pt-1.5')}>
                              {photo && (
                                <Image key={photo.id} height={64} width={64}
                                       src={process.env.REACT_APP_ASSET_URL + photo.path}
                                       alt={photo.filename} noImageAlt={'Image not found'} style={{
                                  'margin': '0 auto',
                                  'height': '11rem',
                                  'width': '11rem',
                                  'background': '#eef2ff',
                                  'borderRadius': '0.375rem'
                                }}/>
                              )}
                            </div>
                        })}
                      </div>
                      {treatmentSelectorRef && firstImageSelected && secondImageSelected &&
                      <Fragment>
                        <div className="mt-6 mb-3 text-sm">
                          <p>Por defecto el slider del WooW es vertical (de izquierda a derecha). Si lo prefieres puedes
                            cambiarlo a horizontal.</p>
                        </div>
                        <div className="relative flex items-start text-left">
                          <div className="flex items-center h-5">
                            <input
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              ref={verticalCheckboxRef}
                              defaultChecked={woowPreview.is_vertical}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Slider horizontal
                            </label>
                            <p className="text-gray-500">El slider será horizontal e irá de arriba a abajo</p>
                          </div>
                        </div>
                      </Fragment>
                      }
                    </Fragment>
                    }
                    {showPreview &&
                    <div className={'flex flex-wrap content-center justify-center'}>
                      <img src={process.env.REACT_APP_ASSET_URL + firstImageSelected.path}
                           alt={firstImageSelected.filename} className={'ml-2 my-3 rounded-md h-16 w-16'}
                           key={firstImageSelected.id}/>
                      <img src={process.env.REACT_APP_ASSET_URL + secondImageSelected.path}
                           alt={secondImageSelected.filename} className={'ml-2 my-3 rounded-md h-16 w-16'}
                           key={secondImageSelected.id}/>
                      <ReactCompareImage leftImage={process.env.REACT_APP_ASSET_URL + woowPreview.before_photo.path}
                                         rightImage={process.env.REACT_APP_ASSET_URL + woowPreview.after_photo.path}
                                         vertical={woowPreview.is_vertical} sliderLineWidth={6} aspectRatio="wider"
                                         leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                         rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>
                      {props.logo && (
                        <img className="h-16 w-auto mt-5 mb-3" src={process.env.REACT_APP_ASSET_URL + props.logo.path}
                             alt={'Company Logo'}/>
                      )}
                      {!props.logo && (
                        <img
                          className="h-10 w-auto mt-5 mb-3"
                          src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                          alt="Woow logo"/>
                      )}
                      <p className={'text-xl text-center w-full'}>{woowPreview.title}</p>
                    </div>
                    }
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {showPreview && props.isLoading && <LoadingSpinner/>}
                    {showPreview && !props.isLoading &&
                    <Fragment>
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => props.onAction(woowPreview)}
                      >
                        {props.confirmLabel}
                      </button>
                      <button
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={backToForm}
                      >
                        Atrás
                      </button>
                    </Fragment>
                    }
                    {treatmentSelected && firstImageSelected && secondImageSelected && !showPreview &&
                    <Fragment>

                      <button
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={generatePreview}
                      >
                        Generar vista previa
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={props.onCancel}
                        ref={cancelButtonRef}
                      >
                        {props.dismissLabel}
                      </button>
                    </Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default WoowGeneratorModal
