import RegisterForm from '../../components/Auth/register/RegisterForm'
import AuthLayout from '../../components/Layout/Auth/AuthLayout'

const RegisterPage = () => {
  document.title = 'Registro | WOOW'

  return (
    <AuthLayout>
      <RegisterForm/>
    </AuthLayout>
  )
}

export default RegisterPage
