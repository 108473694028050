import { useToasts } from 'react-toast-notifications'
import InputWithError from '../../UI/InputWithError'
import { Link, useHistory } from 'react-router-dom'
import { Fragment, useRef, useState } from 'react'

const RecoveryPasswordForm = (props) => {
  const {addToast} = useToasts()

  const emailInputRef = useRef()

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const submitHandler = (event) => {
    event.preventDefault()

    const enteredEmail = emailInputRef.current.value

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/forgot-password', {
      method: 'POST',
      body: JSON.stringify({
        email: enteredEmail
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        email: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      addToast(data.message, {appearance: 'success', autoDismiss: true})
      history.replace('/login')
    }).catch(err => {
      if (err.errors) {
        setErrors({
          email: err.errors.email && err.errors.email[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  return (<Fragment>
      <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto text-center">
          <p className="text-3xl font-lexendSemiBold sm:text-4xl">¿Has olvidado tu contraseña?</p>
          <p className="text-2md font-lexendThin">
            Indica tu dirección de correo
          </p>
        </div>
        <form onSubmit={submitHandler} className="max-w-xl mx-auto mt-8 mb-0 space-y-2">
          <div className="relative">
            <InputWithError label={'Email'} type={'email'} name={'email'} id={'email-input'} required={true}
                            setRef={emailInputRef} placeholder={'johndoe@email.com'} errorMessage={errors.email}
                            description={'email'}/>
          </div>
          {!isLoading && (
            <div className="flex items-center justify-center">
              <button type="submit"
                      className="inline-block my-9 px-20 py-2.5 text-white bg-primary-default rounded-lg font-bold">
                Enviar
              </button>
            </div>
          )}
        </form>
        <div className="max-w-lg mx-auto text-center">
          <p className="text-sm">
            <Link to="/login" className="font-bold text-primary-default">Iniciar sesión</Link>
          </p>
        </div>
      </div>
    </Fragment>
  )
}

export default RecoveryPasswordForm
