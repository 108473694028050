import ActionPanelWithInputModal from '../../UI/ActionPanelWithInputModal'
import { Fragment, useContext, useRef, useState } from 'react'
import AuthContext from '../../../store/auth-context'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from '../../Helpers/utils'
import FileInput from '../../UI/FileInput'
import AlertModal from '../../UI/AlertModal'

const UserConsents = props => {
  const {addToast} = useToasts()

  const authCtx = useContext(AuthContext)

  const consentInputRef = useRef()
  const consentNameInputRef = useRef()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [deleteConsentId, setDeleteConsentId] = useState()
  const [updateConsentId, setUpdateConsentId] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const formSubmissionHandler = () => {
    const formData = new FormData()
    formData.append('consent', consentInputRef.current.files[0])
    formData.append('user_id', props.user.id)

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/consents', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      },
      body: formData
    }).then(res => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Upload consent failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      props.user.consents.push(data.data)
      addToast('Documento subido con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const updateConsentFilename = event => {
    event.preventDefault()

    const introducedFilename = consentNameInputRef.current.value
    const formData = new FormData()

    if (introducedFilename.length > 0) {
      formData.append('_method', 'PATCH')
      formData.append('filename', introducedFilename)
    } else {
      setOpenUpdateModal(!openUpdateModal)
      setIsLoading(false)
      return
    }

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/consents/' + updateConsentId, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      setOpenUpdateModal(!openUpdateModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Update consent failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      props.user.consents = data.data
      consentInputRef.current.value = ''
      addToast('Documento renombrado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelDelete = () => {
    setOpenDeleteModal(!openDeleteModal)
  }

  const updateConsentHandler = consentId => {
    setOpenUpdateModal(!openUpdateModal)
    setUpdateConsentId(consentId)
  }

  const deleteConsent = event => {
    event.preventDefault()

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/consents/' + deleteConsentId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      setOpenDeleteModal(!openDeleteModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Delete consent failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      props.user.consents = data.data
      addToast('Documento eliminado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelUpdate = () => {
    setOpenUpdateModal(!openUpdateModal)
  }

  const deleteConsentHandler = consentId => {
    setOpenDeleteModal(!openDeleteModal)
    setDeleteConsentId(consentId)
  }

  const downloadFile = (consent) => {
    fetch(process.env.REACT_APP_API_URL + '/consents/' + consent.id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob], {type: "application/pdf"}),
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          consent.name,
        )

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      }).catch((err) => {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  return (
    <div className="items-center justify-center shadow-md bg-white w-full pt-6 pb-4 px-10 gap-4">

      <div className="w-full">
        {!isLoading && <FileInput key={'consent'} name={'consent'} accept={'application/pdf'} ref={consentInputRef}
                                  btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex'} filename={false}
                                  btnText={'Importar Archivo'} submitHandler={formSubmissionHandler} addUploadButton={true}/>
        }
        {isLoading && <LoadingSpinner/>}
      </div>
      <div className="w-full">
        {props.user.consents.map(consent => {
          return (
            <Fragment key={consent.id}>
              <p className={'font-lexendSemiBold text-xl border-b-2 py-5 cursor-pointer'}
                 onClick={() => downloadFile(consent)}>{consent.name}
              </p>
              <Menu as="span" className="ml-3 float-right relative bottom-10">
                {({open}) => (
                  <Fragment>
                    <div>
                      <Menu.Button
                        className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none right-4">
                        <Menu.Item>
                          {({active}) => (
                            <p className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                               onClick={() => updateConsentHandler(consent.id)}>
                              Renombrar
                            </p>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({active}) => (
                            <p className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                               onClick={() => deleteConsentHandler(consent.id)}>
                              Eliminar
                            </p>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Fragment>
                )}
              </Menu>
            </Fragment>
          )
        })}
      </div>

      {openUpdateModal &&
      <ActionPanelWithInputModal key={'modal'} open={openUpdateModal} onCancel={cancelUpdate} setRef={consentNameInputRef}
                                 title={'Cambiar nombre del documento'} confirmLabel={'Cambiar'} dismissLabel={'Cancelar'}
                                 onAction={updateConsentFilename} description={'Indique el nuevo nombre del documento'}/>
      }

      {openDeleteModal &&
      <AlertModal key={'modal'} open={openDeleteModal} onCancel={cancelDelete} title={'Eliminar documento'}
                  onDeleteAction={deleteConsent} confirmLabel={'Eliminar'} dismissLabel={'Cancelar'}
                  description={'¿Estás seguro de eliminar este documento?'}/>
      }
    </div>
  )
}

export default UserConsents
