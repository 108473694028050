import { NavLink } from 'react-router-dom'
import Paginator from '../UI/Paginator'
import { Fragment } from 'react'

const List = props => (
  <Fragment>
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
              <tr>
                {props.headers.map((header, i) => (
                  <th scope="col" key={i} className="px-6 py-3 text-left uppercase font-medium text-sm tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {props.users && props.users.map((user) => (
                <tr key={user.id}>
                  <td
                    className="px-6 py-4 whitespace-nowrap font-lexendSemiBold text-secondary-default hover:text-primary-default">
                    <NavLink to={{pathname: '/user/' + user.id, user: user}}>
                      {user.name + ' ' + user.last_name}
                    </NavLink>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.contact_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.email}
                  </td>
                </tr>
              ))}
              {!props.users && (
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-lexendSemiBold" colSpan={3}>
                    No existen registros
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    {props.links && props.meta && (
      <Paginator links={props.links} meta={props.meta} onPagination={props.onPagination}/>
    )}
  </Fragment>
)

export default List
