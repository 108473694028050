import ButtonWithLeadingIcon from '../UI/ButtonWithLeadingIcon'
import CompanyContext from '../../store/company-context'
import { useToasts } from 'react-toast-notifications'
import { useContext, useRef, useState } from 'react'
import AuthContext from '../../store/auth-context'
import LoadingSpinner from '../UI/LoadingSpinner'
import InputWithError from '../UI/InputWithError'
import FileInput from '../UI/FileInput'
import { useHistory } from 'react-router-dom'

const NewUserForm = () => {
  const {addToast} = useToasts()

  const companyCtx = useContext(CompanyContext)
  const authCtx = useContext(AuthContext)
  const history = useHistory()

  const userPhotoInputRef = useRef()
  const nameInputRef = useRef()
  const lastNameInputRef = useRef()
  const identificationNumberInputRef = useRef()
  const contactNumberInputRef = useRef()
  const emailInputRef = useRef()
  const consentInputRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    user_photo: '',
    name: '',
    last_name: '',
    identification_number: '',
    contact_number: '',
    email: '',
    consent: ''
  })

  const formSubmissionHandler = (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('user_photo', userPhotoInputRef.current.files[0])
    formData.append('last_name', lastNameInputRef.current.value)
    formData.append('name', nameInputRef.current.value)
    formData.append('identification_number', identificationNumberInputRef.current.value)
    formData.append('contact_number', contactNumberInputRef.current.value)
    formData.append('email', emailInputRef.current.value)
    formData.append('consent', consentInputRef.current.files[0])

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/users', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        user_photo: '',
        name: '',
        last_name: '',
        identification_number: '',
        contact_number: '',
        email: '',
        consent: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      companyCtx.company.users.push(data.user)
      companyCtx.setCompany(data.company)
      history.goBack()
    }).catch((err) => {
      if (err.errors) {
        setErrors({
          user_photo: err.errors.user_photo && err.errors.user_photo[0],
          name: err.errors.name && err.errors.name[0],
          last_name: err.errors.last_name && err.errors.last_name[0],
          identification_number: err.errors.identification_number && err.errors.identification_number[0],
          contact_number: err.errors.contact_number && err.errors.contact_number[0],
          email: err.errors.email && err.errors.email[0],
          consent: err.errors.consent && err.errors.consent[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  return (
    <form onSubmit={formSubmissionHandler} encType={'multipart/form-data'} className={'w-full'}>
      <div className="bg-white w-full px-10">
        <div
          className="flex flex-wrap md:grid md:grid-cols-3 md:space-y-0 space-y-1 flex pt-6 items-center justify-center">
            <span className="flex h-24 w-24 rounded-full overflow-hidden bg-gray-100 items-center">
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
              </svg>
            </span>
          <FileInput key={'user-photo'} name={'user-photo'} accept={'image/*'}
                     btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex sm:ml-5 md:ml-0'}
                     withoutFileName={false} btnText={'Añadir foto'} ref={userPhotoInputRef}/>
        </div>
        <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
          <p className={'font-lexendSemiBold text-lg'}>Nombre<span style={{'color': 'red'}}>*</span>:</p>
          <InputWithError label={''} type={'text'} name={'name'} id={'first-name-input'}
                          setRef={nameInputRef} placeholder={''}
                          errorMessage={errors.name}
                          description={'nombre del nuevo usuario'}
                          required={true}/>
        </div>
        <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
          <p className={'font-lexendSemiBold text-lg'}>Apellidos<span style={{'color': 'red'}}>*</span>:</p>
          <InputWithError label={''} type={'text'} name={'last_name'} id={'last-name-input'}
                          setRef={lastNameInputRef} placeholder={''}
                          errorMessage={errors.last_name}
                          description={'apellidos del nuevo usuario'}
                          required={true}/>
        </div>
        <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
          <p className={'font-lexendSemiBold text-lg'}>DNI:</p>
          <InputWithError label={''} type={'text'} name={'identification_number'} id={'identification-number-input'}
                          setRef={identificationNumberInputRef} placeholder={''}
                          errorMessage={errors.identification_number}
                          description={'dni del nuevo usuario'}
                          required={true}/>
        </div>
        <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 items-center">
          <p className={'font-lexendSemiBold text-lg'}>Teléfono:</p>
          <InputWithError label={''} type={'text'} name={'contact_number'} id={'contact-number-input'}
                          setRef={contactNumberInputRef} placeholder={''}
                          errorMessage={errors.contact_number}
                          description={'teléfono del nuevo usuario'}
                          required={true}/>
        </div>
        <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 pb-4 items-center border-b-2">
          <p className={'font-lexendSemiBold text-lg'}>Email:</p>
          <InputWithError label={''} type={'text'} name={'email'} id={'email-input'}
                          setRef={emailInputRef} placeholder={''}
                          errorMessage={errors.email}
                          description={'email del nuevo usuario'}
                          required={true}/>
        </div>
        <div className="pt-6 pb-4 items-center">
          <p className="font-lexendSemiBold text-xl">Consentimiento informado:</p>
          <p>Puedes importar el consentimiento informado del {companyCtx.customer}, estos quedarán guardados en la
            ficha del {companyCtx.customer}</p>
          <FileInput key={'consent'} name={'consent'} accept={'application/pdf'}
                     btnClasses={'bg-white text-secondary-default px-7 border-gray-100 inline-flex sm:ml-5'}
                     btnText={'Importar Archivo'} ref={consentInputRef}/>
        </div>
      </div>
      <div className={'flex items-center bg-white shadow-md px-10 border-t-2 py-6 px-8 justify-end'}>
        {!isLoading && (
          <ButtonWithLeadingIcon text={'Guardar'} leadingIcon={''} click={formSubmissionHandler}
                                 classes={'bg-primary-default text-white px-12 hover:text-primary-default'}/>
        )}
        {isLoading && <LoadingSpinner/>}
      </div>
    </form>
  )
}

export default NewUserForm
