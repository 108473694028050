import ModalTemplate from '../../UI/ModalTemplate'
import { Dialog } from '@headlessui/react'
import { useRef } from 'react'

const FolderSelectorModal = (props) => {
  const cancelButtonRef = useRef()

  return (
    <ModalTemplate open={props.open} setRef={props.setRef} onCancel={props.onCancel}>
      <div
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium">
              {props.title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {props.description}
              </p>
            </div>
            <div className="w-full">
              <label htmlFor="treatment" className="block text-sm font-medium text-gray-700 mt-4">
                {props.folderLabel}
              </label>
              <select
                id="folder"
                name="treatment"
                autoComplete="treatment"
                ref={props.setRef}
                defaultValue={props.defaultFolder ? props.defaultFolder.id : false}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value={''}>Ningúna carpeta</option>
                {props.folders.map(folder => {
                  return <option key={folder.id} value={folder.id}>{folder.title}</option>
                })}
              </select>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={props.onSubmit}
              >
                {props.confirmLabel}
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={props.onCancel}
                ref={cancelButtonRef}
              >
                {props.dismissLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default FolderSelectorModal
