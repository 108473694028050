import { Fragment, useContext, useRef, useState } from 'react'
import CompanyContext from '../../../store/company-context'
import AuthContext from '../../../store/auth-context'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from '../../UI/LoadingSpinner'
import InputWithError from '../../UI/InputWithError'
import { Link, useHistory } from 'react-router-dom'
import PlansContext from '../../../store/plans-context'

const LoginForm = () => {
  const {addToast} = useToasts()

  const emailInputRef = useRef()
  const passwordInputRef = useRef()

  const history = useHistory()
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)
  const planCtx = useContext(PlansContext)

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  })

  const submitHandler = (event) => {
    event.preventDefault()

    const enteredEmail = emailInputRef.current.value
    const enteredPassword = passwordInputRef.current.value

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/login', {
      method: 'POST',
      body: JSON.stringify({
        email: enteredEmail,
        password: enteredPassword
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        email: '',
        password: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          throw data
        })
      }
    }).then((data) => {
      const expirationTime = new Date(
        new Date().getTime() + +data.expires_in * 1000
      )
      companyCtx.setCompany(data.company)
      planCtx.setPlans(data.plans, data.company.subscription)
      authCtx.login(data.token_type, data.access_token, expirationTime.toISOString())
      if (data.first_login) {
        history.replace('/profile')
      } else {
        history.replace('/')
      }
    }).catch(err => {
      if (err.errors) {
        setErrors({
          email: err.errors.email && err.errors.email[0],
          password: err.errors.password && err.errors.password[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }

    })
  }

  return (
    <Fragment>
      <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto text-center">
          <p className="text-3xl font-lexendSemiBold sm:text-4xl">¡Bienvenido!</p>
          <p className="text-2md font-lexendThin">
            Inicia sesión con tu cuenta de WOOW
          </p>
        </div>
        <form onSubmit={submitHandler} className="max-w-xl mx-auto mt-8 mb-0 space-y-2">
          <div className="relative">
            <InputWithError label={'Email'} type={'email'} name={'email'} id={'email-input'} required={true}
                            setRef={emailInputRef} placeholder={'johndoe@email.com'} errorMessage={errors.email}
                            description={'email'}/>
          </div>
          <div className="relative">
            <InputWithError label={'Contraseña'} type={'password'} name={'password'} id={'password-input'} required={true}
                            setRef={passwordInputRef} placeholder={'***********'} errorMessage={errors.password}
                            description={'email'}/>
          </div>
          <div className="text-right">
            <p className="text-sm">
              <Link to="/recovery-password">¿Has olvidado la contraseña?</Link>
            </p>
          </div>
          {!isLoading && (
            <div className="flex items-center justify-center">
              <button type="submit"
                      className="inline-block my-9 px-20 py-2.5 text-white bg-primary-default rounded-lg font-bold">
                Iniciar Sesión
              </button>
            </div>
          )}
          {isLoading && (
            <LoadingSpinner/>
          )}
        </form>
        <div className="max-w-lg mx-auto text-center">
          <p className="text-sm">
            ¿No tienes cuenta? <Link to="/register" className="font-bold text-primary-default">Empezar período de prueba
            gratuito de un mes</Link>
          </p>
        </div>
      </div>
    </Fragment>
  )
}

export default LoginForm
