const ButtonWithLeadingIcon = (props) => (
  <button
    type={props.type || 'button'}
    className={'flex items-center h-12 sm:w-fi px-3 py-1 my-3 border border-transparent shadow-sm leading-4 rounded-md font-bold justify-center hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-default ' + props.classes}
    onClick={props.click || false}>
    {props.leadingIcon}
    {props.text}
  </button>
)

export default ButtonWithLeadingIcon
