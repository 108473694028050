import { classNames } from '../Helpers/utils'
import { NavLink } from 'react-router-dom'
import { Fragment } from 'react'

const Tabs = props => {
  return (
    <Fragment>
      <div className="sm:hidden my-3">
        <label htmlFor="tabs" className="sr-only">
          Selecciona una pestaña
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onChange={(e) => props.selectedTab(e.target.value)}
          >
          {props.tabs.map((tab) => (
            <option key={tab.name} value={JSON.stringify(tab)}>{tab.name}</option>
          ))}
        </select>
        <div className="bg-white">
          {props.children}
        </div>
      </div>
      <div className="hidden sm:block my-3">
        <Fragment>
          <nav className="-mb-px flex space-x-1 lg:space-x-4" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={{pathname: props.href, user: props.user}}
                aria-current={tab.current ? 'page' : undefined}
                className={classNames(
                  tab.current
                    ? 'bg-white font-lexendBold text-primary-default'
                    : 'hover:text-secondary-default hover:border-gray-300 text-primary-default bg-indigo-50 font-lexendSemiBold',
                  'whitespace-nowrap py-3 px-1 font-medium text-sm px-2 lg:px-5 rounded'
                )}
              onClick={() => props.selectedTab(tab)}>
                {tab.name}
              </NavLink>
            ))}
          </nav>
          <div className="bg-white">
            {props.children}
          </div>
        </Fragment>
      </div>
    </Fragment>
  )
}

export default Tabs
