import { DotsVerticalIcon } from '@heroicons/react/solid'
import FullImageModal from '../../UI/FullImageModal'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from '../../Helpers/utils'
import { Fragment, useState } from 'react'

const BeforeAfterCard = (props) => {
  const [openFullImageModal, setOpenFullImageModal] = useState(false)
  const [fullImageModal, setFullImageModal] = useState()

  const fullImageHandler = photo => {
    setFullImageModal(photo)
    setOpenFullImageModal(true)
  }

  const closeFullImageHandler = () => {
    setFullImageModal('')
    setOpenFullImageModal(false)
  }

  return (
    <div className={classNames(
      props.image.is_vertical_image || props.image.is_vertical_image ? 'md:w-1/2' : 'md:w-1/2',
      'shadow-lg bg-white px-4 py-5 sm:px-6 w-full sm:w-1/2 sm:mx-auto md:mx-0 my-5 rounded-md object-none'
    )}>
      <div
        className="flex flex-wrap min-w-0 flex-1 border rounded-md mx-1 my-3 2xl:hover:border-primary-default p-1 mb-8"
        onClick={() => fullImageHandler(props.image)}>
        <img src={process.env.REACT_APP_ASSET_URL + props.image.path}
             alt={props.image.filename} className={'rounded-md'}
             key={props.image.id}/>
      </div>
      <div className={'min-w-0 flex-1'}>
        <div className="float-right">
          <Menu as="div" className="relative inline-block text-left bottom-5">
            {({open}) => (
              <>
                <div>
                  <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.download(props.image)}>
                            <span>Descargar</span>
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <p
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm cursor-pointer'
                            )}
                            onClick={() => props.delete(props.image.id)}>
                            <span>Eliminar</span>
                          </p>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        <p className={'mt-3'}>{props.image.filename}</p>
      </div>

      {openFullImageModal &&
      <FullImageModal open={openFullImageModal} onCancel={closeFullImageHandler} dismissLabel={'Cerrar'}
                      image={fullImageModal}/>
      }
    </div>
  )
}

export default BeforeAfterCard
