import ActionPanelWithInputModal from '../../UI/ActionPanelWithInputModal'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import FolderSelectorModal from '../Woows/FolderSelectorModal'
import CompanyContext from '../../../store/company-context'
import WoowPreviewModal from '../Woows/WoowPreviewModal'
import { useToasts } from 'react-toast-notifications'
import AuthContext from '../../../store/auth-context'
import ShareUrlModal from '../Woows/ShareUrlModal'
import AlertModal from '../../UI/AlertModal'
import { NavLink } from 'react-router-dom'
import WoowCard from '../Woows/WoowCard'

const tab = {name: 'Galería de fotos', href: '#photos', current: false}

const UserWoows = props => {
  const {addToast} = useToasts()

  const companyCtx = useContext(CompanyContext)
  const authCtx = useContext(AuthContext)

  const woowTitleInputRef = useRef()
  const folderSelectorRef = useRef()

  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openFolderModal, setOpenFolderModal] = useState(false)
  const [woowCompanyLogo, setWoowCompanyLogo] = useState(null)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [woowFolder, setWoowFolder] = useState(null)
  const [selectedWoowId, setSelectedWoowId] = useState()
  const [previewWoow, setPreviewWoow] = useState()
  const [shareUrl, setShareUrl] = useState()

  useEffect(() => {
    const logo = companyCtx.company.logos.find(logo => {
      return logo.type === 'woow'
    }, [])

    setWoowCompanyLogo(logo)
  }, [companyCtx.company.logos])

  const previewWoowHandler = woow => {
    setPreviewWoow(woow)
    setOpenPreviewModal(!openPreviewModal)
  }

  const featureWoowHandler = (woowId, isFeatured) => {
    markWoowAsFeatured(woowId, isFeatured)
  }

  const markWoowAsFeatured = (selectedWoowId, isFeatured) => {
    const formData = new FormData()

    formData.append('_method', 'PATCH')
    formData.append('is_featured', +isFeatured)

    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId + '/mark-as-featured', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Update WOOW failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      props.user.woows = data.user_woows
      addToast('WOOW marcado como destacado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const updateWoowHandler = woowId => {
    setOpenUpdateModal(!openUpdateModal)
    setSelectedWoowId(woowId)
  }

  const updateWoowTitle = event => {
    event.preventDefault()

    const introducedTitle = woowTitleInputRef.current.value
    const formData = new FormData()

    if (introducedTitle.length > 0) {
      formData.append('_method', 'PATCH')
      formData.append('title', introducedTitle)
    } else {
      setOpenUpdateModal(!openUpdateModal)
      return
    }

    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenUpdateModal(!openUpdateModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Update WOOW failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      props.user.woows = data.user_woows
      addToast('WOOW renombrado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelUpdate = () => {
    setOpenUpdateModal(!openUpdateModal)
  }

  const deleteWoowHandler = woowId => {
    setSelectedWoowId(woowId)
    setOpenDeleteModal(!openDeleteModal)
  }

  const cancelDelete = () => {
    setOpenDeleteModal(!openDeleteModal)
  }

  const deleteWoow = event => {
    event.preventDefault()

    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId + '/delete', {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenDeleteModal(!openDeleteModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Delete consent failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      props.user.woows = data.user_woows
      addToast('Documento eliminado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const cancelAssignFolderHandler = () => {
    setOpenFolderModal(!openFolderModal)
  }

  const assignFolderHandler = (folder, woowId) => {
    setWoowFolder(folder)
    setSelectedWoowId(woowId)
    setOpenFolderModal(!openFolderModal)
  }

  const assignFolder = () => {
    const folderSelected = folderSelectorRef.current.value

    const formData = new FormData()

    formData.append('_method', 'PATCH')
    formData.append('woow_folder', folderSelected)

    fetch(process.env.REACT_APP_API_URL + '/woows/' + selectedWoowId + '/assign-folder', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setOpenFolderModal(!openFolderModal)
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then(data => {
          let errorMessage = (data.message) ? data.message : 'Assign WOOW failed!'
          throw new Error(errorMessage)
        })
      }
    }).then((data) => {
      companyCtx.setCompany(data.company_update)
      props.user.woows = data.user_woows
      addToast('Woow asignado con éxito', {appearance: 'success', autoDismiss: true,})
    }).catch((err) => {
      addToast(err.message, {appearance: 'error', autoDismiss: true,})
    })
  }

  const shareWoowHandler = uuid => {
    let domain = (new URL(document.location.href))

    setShareUrl(domain.protocol + '//' + domain.hostname + ':' + domain.port + '/woow/' + uuid)
    setOpenShareModal(!openShareModal)
  }

  return (
    <Fragment>
      <div className="items-center justify-center shadow-md bg-white w-full pt-6 pb-4 px-10">
        <p>Para generar un nuevo WOOW puedes hacerlo desde la pestaña <NavLink
          key={tab.name}
          to={{pathname: props.href, user: props.user}}
          onClick={() => props.selectedTab(tab)}
          className={'text-primary-default font-bold'}>
          {tab.name}
        </NavLink></p>
        <div className={'flex flex-wrap border-box mx-2'}>
          {props.user.woows.map(woow => {
            return <WoowCard key={woow.id} woow={woow} delete={deleteWoowHandler} assignFolder={assignFolderHandler}
                             feature={featureWoowHandler} preview={previewWoowHandler} update={updateWoowHandler}
                             share={shareWoowHandler}/>
          })}
        </div>
      </div>
      {openUpdateModal &&
      <ActionPanelWithInputModal key={'modal'} open={openUpdateModal} onCancel={cancelUpdate} setRef={woowTitleInputRef}
                                 title={'Cambiar título del Woow'} confirmLabel={'Cambiar'} dismissLabel={'Cancelar'}
                                 onAction={updateWoowTitle} description={'Indique el nuevo título del Woow'}/>
      }
      {openDeleteModal &&
      <AlertModal key={'modal'} open={openDeleteModal} onCancel={cancelDelete} title={'Eliminar Woow'}
                  onDeleteAction={deleteWoow} confirmLabel={'Eliminar'} dismissLabel={'Cancelar'}
                  description={'¿Estás seguro de eliminar este Woow?'}/>
      }
      {openPreviewModal &&
      <WoowPreviewModal open={openPreviewModal} onCancel={previewWoowHandler} dismissLabel={'Cerrar'} woow={previewWoow}
                        logo={woowCompanyLogo}/>
      }
      {openFolderModal &&
      <FolderSelectorModal open={openFolderModal} onCancel={cancelAssignFolderHandler} onSubmit={assignFolder}
                           confirmLabel={'Asignar'} dismissLabel={'Cancelar'} folders={companyCtx.company.woow_folders}
                           setRef={folderSelectorRef} title={'Asignación de carpeta'} defaultFolder={woowFolder}
                           description={'Seleccione una carpeta para categorizar el woow'}
                           folderLabel={'Carpetas disponibles'}/>
      }
      {openShareModal &&
      <ShareUrlModal open={openShareModal} onCancel={shareWoowHandler} dismissLabel={'Cerrar'} url={shareUrl}/>
      }
    </Fragment>
  )
}

export default UserWoows
