const Paginator = (props) => (
  <div className="py-3 flex items-center justify-between">
    <div className="flex-1 flex justify-between sm:hidden">
      {props.links.prev !== null && (
        <button
          data-url={props.links.prev}
          onClick={props.onPagination}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Anterior
        </button>
      )}
      {props.links.next !== null && (
        <button
          data-url={props.links.next}
          onClick={props.onPagination}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Siguiente
        </button>
      )}
    </div>
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between round">
      <nav className="relative z-0 inline-flex" aria-label="Pagination">
        {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
        {props.meta.links.map((link, i, arr) => (
          (arr.length - 1 === i || i === 0)
            ? (
              <button
                key={i}
                data-url={link.url}
                onClick={props.onPagination}
                aria-current="page"
                className={'z-10 relative inline-flex items-center px-2 py-1 font-bold text-sm'}>
                {link.label}
              </button>
            )
            : (
              <button
                key={i}
                data-url={link.url}
                onClick={props.onPagination}
                aria-current="page"
                className={link.active
                  ? 'z-10 bg-secondary-default text-white relative inline-flex px-3 py-1 font-bold text-sm rounded-lg mx-0.5'
                  : 'bg-indigo-100 text-secondary-default hover:bg-gray-200 relative inline-flex px-3 py-1 text-sm rounded-lg mx-0.5'}>
                {link.label}
              </button>
            )
        ))}
      </nav>
    </div>
  </div>
)

export default Paginator
