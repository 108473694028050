import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from '../UI/LoadingSpinner'
import ModalTemplate from '../UI/ModalTemplate'
import { Dialog } from '@headlessui/react'
import { useRef } from 'react'

import pay_safe from './assets/pay-safe.jpg'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '',
      color: '#000000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#4e8bf2'
      },
      '::placeholder': {
        color: '#4e8bf2'
      }
    },
    invalid: {
      iconColor: 'red',
      color: 'red'
    }
  }
}

const PaymentCard = props => {
  const {addToast} = useToasts()

  const cancelButtonRef = useRef()
  const nameInputRef = useRef()
  const emailInputRef = useRef()
  const addressInputRef = useRef()
  const cityInputRef = useRef()
  const stateInputRef = useRef()
  const idNumberInputRef = useRef()

  const stripe = useStripe()
  const elements = useElements()

  const formatPriceStr = (plan) => {
    if (plan.amount === 0) {
      return '0'
    }

    const price = plan.amount.toString()
    const decimals = plan.decimals || 2

    return price.substring(0, price.length - decimals) + ',' + price.substring(price.length - decimals)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const enteredName = nameInputRef.current.value
    const enteredEmail = emailInputRef.current.value
    const enteredAddress = addressInputRef.current.value
    const enteredCity = cityInputRef.current.value
    const enteredState = stateInputRef.current.value
    const enteredIdNumber = idNumberInputRef.current.value

    const billing_details = {
      address: {
        city: enteredCity,
        state: enteredState,
        country: 'ES',
        line1: enteredAddress
      },
      email: enteredEmail,
      name: enteredName,
    }
    const metadata = {
      id_number: enteredIdNumber
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billing_details,
      metadata: metadata
    })

    if (error) {
      addToast(error.message, {appearance: 'error', autoDismiss: true,})
    } else {
      props.processPayment(paymentMethod, stripe, cardElement)
    }
  }

  return (
    <ModalTemplate open={props.open} setRef={props.cancelButtonRef} onCancel={props.onCancel}>
      <div
        className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
        <div className="mt-3 text-center sm:mt-0 mb-4 sm:text-left">
          <Dialog.Title as="h3" className="text-2xl font-bold leading-6">
            {props.plan.product.name}
          </Dialog.Title>
          <div className="mt-2">
            <p className={'mb-2'}>Hasta {props.plan.product.metadata.limit_amount} {props.usrStr}</p>
            <p className="text-sm text-gray-500 text-right">
              {props.plan.price === 0 ? 'GRATIS' : '€ ' + formatPriceStr(props.plan) + '/mes'}
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={'text-sm'}>
            <p>Puedes cancelar tu suscripción a WooW en cualquier momento en la sección "Mi Perfil > Plan Actual"</p>
            <br/>
            <p>Además los datos de tu cuenta son tuyos, puedes descargarlos en cualquier momento. Sin ataduras, sin
              compromisos.</p>
            <br/>
          </div>
          <div className={'w-full my-2 text-lg text-center underline text-primary-default font-lexendSemiBold'}>
            <p>Datos de facturación</p>
          </div>
          <div className="w-full mt-2">
            <label htmlFor="name" className="">
              Nombre
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Nombre"
              ref={nameInputRef}
              required={true}
              defaultValue={props.company.name}
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="email" className="">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Dirección de correo electrónico"
              ref={emailInputRef}
              required={true}
              defaultValue={props.company.email}
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="address" className="">
              Dirección
            </label>
            <input
              type="text"
              name="address"
              id="address"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Dirección"
              ref={addressInputRef}
              required={true}
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="city" className="">
              Ciudad
            </label>
            <input
              type="text"
              name="city"
              id="city"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Ciudad"
              ref={cityInputRef}
              required={true}
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="state" className="">
              Provincia
            </label>
            <input
              type="text"
              name="state"
              id="state"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Provincia"
              ref={stateInputRef}
              required={true}
            />
          </div>
          <div className="w-full mt-2">
            <label htmlFor="name" className="">
              Número de identificación
            </label>
            <input
              type="text"
              name="id_number"
              id="id_number"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="DNI/NIE/CIF"
              ref={idNumberInputRef}
              required={true}
            />
          </div>
          <div className={'w-full my-4 text-lg text-center underline text-primary-default font-lexendSemiBold'}>
            <p>Datos de forma de pago</p>
          </div>
          <CardElement options={CARD_OPTIONS} className={'bg-gray-10 rounded-md px-2 py-2 mt-2 border'}/>
          <img src={pay_safe} alt={pay_safe} className={'h-16 mx-auto my-5'}/>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {props.processingPayment && <LoadingSpinner/>}
            {!props.processingPayment && (
              <>
                <button
                  type="submit"
                  disabled={!stripe && !props.processingPayment}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-default text-base font-medium text-white hover:bg-primary-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-default sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {props.confirmLabel}
                </button>
                <button
                  type="button"
                  disabled={!stripe && !props.processingPayment}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={props.onCancel}
                  ref={cancelButtonRef}
                >
                  {props.dismissLabel}
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </ModalTemplate>
  )
}

export default PaymentCard
