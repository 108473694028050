import { Dialog, Transition } from '@headlessui/react'
import { useToasts } from 'react-toast-notifications'
import LoadingSpinner from '../../UI/LoadingSpinner'
import InputWithError from '../../UI/InputWithError'
import { Fragment, useRef, useState } from 'react'
import { classNames } from '../../Helpers/utils'
import { toJpeg } from 'html-to-image'
import Image from 'react-image-resizer'

const BeforeAfterGeneratorModal = props => {
  const {addToast} = useToasts()

  const treatmentSelectorRef = useRef()
  const ref = useRef(null)
  const cancelButtonRef = useRef()
  const titleInputRef = useRef()

  const [secondImageSelected, setSecondImageSelected] = useState()
  const [firstImageSelected, setFirstImageSelected] = useState()
  const [treatmentSelected, setTreatmentSelected] = useState()
  const [showPreview, setShowPreview] = useState(false)
  const [preview, setPreview] = useState({
    title: '',
  })
  const [errors, setErrors] = useState({
    before_after_title: '',
  })
  const [hideElement, setHideElement] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const generateImage = async () => {
    if (ref.current === null) {
      return
    }

    setIsLoading(!isLoading)
    setHideElement(!hideElement)

    await toJpeg(document.querySelector('#model'), {
      cacheBust: true,
      backgroundColor: 'white',
      quality: 1,
      pixelRatio: 1
    })

    await toJpeg(document.querySelector('#model'), {
      cacheBust: true,
      backgroundColor: 'white',
      quality: 1,
      pixelRatio: 1
    })

    await toJpeg(document.querySelector('#model'), {
      cacheBust: true,
      backgroundColor: 'white',
      quality: 1,
      pixelRatio: 1
    })
      .then((dataUrl) => {
        props.onAction(dataUrl, preview.title)
        setHideElement(!hideElement)
        setIsLoading(!isLoading)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(!isLoading)
      })

  }

  const updateTreatmentSelected = () => {
    const selectedTreatment = treatmentSelectorRef.current.value

    if (selectedTreatment) {
      const finalTreatment = props.treatments.find(treatment => {
        return treatment.id === +selectedTreatment
      })

      setTreatmentSelected(finalTreatment)
    } else {
      setTreatmentSelected(null)
      setFirstImageSelected(null)
      setSecondImageSelected(null)
    }
  }

  const firstImageSelectedHandler = photo => {
    setSecondImageSelected(null)
    setFirstImageSelected(photo)
  }

  const secondImageSelectedHandler = photo => {
    setSecondImageSelected(photo)
  }

  const generatePreview = () => {
    const enteredTitleValue = titleInputRef.current.value
    if (enteredTitleValue === '' || !firstImageSelected || !secondImageSelected) {
      if (enteredTitleValue === '') {
        errors.before_after_title = 'Este campo es obligatorio'
      }
      addToast('Faltan datos necesarios', {appearance: 'error', autoDismiss: true})
      return
    }
    const previewObj = {
      title: titleInputRef.current.value,
      before_photo: firstImageSelected,
      after_photo: secondImageSelected,
    }

    setErrors({
      before_after_title: ''
    })

    setPreview(previewObj)
    setShowPreview(true)
  }

  const backToForm = () => {
    setShowPreview(false)
  }

  const hasVerticalImages = () => {
    return !!(firstImageSelected.is_vertical_image && secondImageSelected.is_vertical_image)
  }

  return (
    <Fragment>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={props.setRef}
          open={props.open}
          onClose={props.onCancel}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-blue-900 bg-opacity-95 transition-opacity"/>
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-4xl w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                    {!showPreview &&
                    <Fragment>
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium">
                        {props.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 mb-3">
                          {props.description}
                        </p>
                      </div>
                    </Fragment>
                    }
                    <div className="w-full">
                      {!showPreview &&
                      <Fragment>
                        <div className={'relative'}>
                          <label htmlFor="treatment" className="font-bold">
                            {props.treatmentLabel}<span style={{'color': 'red'}}>*</span>
                          </label>
                          <select
                            id="treatment"
                            name="treatment"
                            autoComplete="treatment"
                            onChange={updateTreatmentSelected}
                            ref={treatmentSelectorRef}
                            defaultValue={treatmentSelected && treatmentSelected.id}
                            className="w-full p-4 text-sm bg-gray-50 rounded-lg shadow-sm border-none mb-3"
                          >
                            <option value={false}>Selecciona {props.treatmentStr === 'obra' ? 'una' : 'un'} {props.treatmentStr}</option>
                            {props.treatments.map(treatment => {
                              return <option key={treatment.id} value={treatment.id}>{treatment.title}</option>
                            })}
                          </select>
                        </div>
                        {treatmentSelected && (
                          <div className="relative">

                            <InputWithError label={'Título'} type={'text'} name={'title'} id={'title'} required={true}
                                            description={'Título del Antes y Después'}
                                            setRef={titleInputRef} placeholder={'Título del Antes y Después'}
                                            errorMessage={errors.before_after_title} default={preview.title}/>
                          </div>
                        )}
                        {treatmentSelected && treatmentSelected.photos.length < 2 && (
                          <label htmlFor="photos" className={'block text-red-600 font-bold text-center mt-4'}>
                            Debes tener al menos dos fotos {props.treatmentStr === 'obra' ? 'en la' : 'en el'} {props.treatmentStr}}
                          </label>
                        )}
                        {treatmentSelected && treatmentSelected.photos.length >= 2 && (
                          <Fragment>
                            <label htmlFor="photos" className={'block text-sm text-gray-700 mt-4'}>
                              Selecciona la foto del Antes
                            </label>
                            <div className={'flex flex-wrap space-x-2 justify-center md:justify-start'}>
                              {treatmentSelected && treatmentSelected.photos && treatmentSelected.photos.map(photo => {
                                return <div key={photo.id + '_before'}
                                            onClick={() => firstImageSelectedHandler(photo)}
                                            className={classNames(
                                              photo === firstImageSelected ? 'ring-4 ring-indigo-500' : '',
                                              'border rounded-md my-1 hover:border-primary-default pt-1.5')}>
                                  {photo && (
                                    <Image key={photo.id} height={64} width={64}
                                           src={process.env.REACT_APP_ASSET_URL + photo.path}
                                           alt={photo.filename} noImageAlt={'Image not found'} style={{
                                      'margin': '0 auto',
                                      'height': '11rem',
                                      'width': '11rem',
                                      'background': '#eef2ff',
                                      'borderRadius': '0.375rem'
                                    }}/>
                                  )}
                                </div>
                              })}
                            </div>
                          </Fragment>
                        )}
                        {firstImageSelected && (
                          <label htmlFor="photos" className={'block text-sm text-gray-700 mt-4'}>
                            Selecciona la foto del Después
                          </label>
                        )}
                        <div className={'flex flex-wrap space-x-2 justify-center md:justify-start'}>
                          {firstImageSelected && treatmentSelected.photos && treatmentSelected.photos.map(photo => {
                            return photo !== firstImageSelected &&
                              <div key={photo.id + '_after'}
                                   onClick={() => secondImageSelectedHandler(photo)}
                                   className={classNames(
                                     photo === secondImageSelected ? 'ring-4 ring-indigo-500' : '',
                                     'border rounded-md my-1 hover:border-primary-default pt-1.5')}>
                                {photo && (
                                  <Image key={photo.id} height={64} width={64}
                                         src={process.env.REACT_APP_ASSET_URL + photo.path}
                                         alt={photo.filename} noImageAlt={'Image not found'} style={{
                                    'margin': '0 auto',
                                    'height': '11rem',
                                    'width': '11rem',
                                    'background': '#eef2ff',
                                    'borderRadius': '0.375rem'
                                  }}/>
                                )}
                              </div>
                          })}
                        </div>
                      </Fragment>
                      }
                      {showPreview && hasVerticalImages() && (
                        <div className={'px-5 pb-5'}>
                          <div className={'relative'}>
                            <p className={'absolute bottom-0 left-0'}>Antes</p>
                            {props.logo && (
                              <img className="block h-7 w-auto mt-5 mx-auto"
                                   src={process.env.REACT_APP_ASSET_URL + props.logo.path}
                                   alt={'Company Logo'}/>
                            )}
                            {!props.logo && (
                              <img
                                className="block h-7 w-auto mt-5 mx-auto"
                                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                                alt="Woow logo"/>
                            )}
                            <p className={'absolute bottom-0 right-0'}>Después</p>
                          </div>
                          <div className={'grid grid-cols-2 my-3'}>
                            <img src={process.env.REACT_APP_ASSET_URL + firstImageSelected.path}
                                 alt={firstImageSelected.filename} className={'pr-0.5'}
                                 key={firstImageSelected.id}/>
                            <img src={process.env.REACT_APP_ASSET_URL + secondImageSelected.path}
                                 alt={secondImageSelected.filename} className={'pl-0.5'}
                                 key={secondImageSelected.id}/>
                          </div>
                        </div>
                      )}
                      {showPreview && !hasVerticalImages() && (
                        <div className={'px-5 pb-5'}>
                          <div className={'relative'}>
                            <p className={'absolute bottom-0 left-0'}>Antes</p>
                            {props.logo && (
                              <img className="block h-7 w-auto mt-5 mx-auto"
                                   src={process.env.REACT_APP_ASSET_URL + props.logo.path}
                                   alt={'Company Logo'}/>
                            )}
                            {!props.logo && (
                              <img
                                className="block h-7 w-auto mt-5 mx-auto"
                                src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                                alt="Woow logo"/>
                            )}
                          </div>
                          <div className={'grid grid-cols-1 my-3'}>
                            <img src={process.env.REACT_APP_ASSET_URL + firstImageSelected.path}
                                 alt={firstImageSelected.filename} className={'pb-0.5'}
                                 key={firstImageSelected.id}/>
                            <img src={process.env.REACT_APP_ASSET_URL + secondImageSelected.path}
                                 alt={secondImageSelected.filename} className={'pt-0.5'}
                                 key={secondImageSelected.id}/>
                          </div>
                          <div className={'flex flex-col'}>
                            <p className={'text-right'}>Después</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {showPreview && props.isLoading && <LoadingSpinner/>}
                      {showPreview && !props.isLoading &&
                      <Fragment>
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={generateImage}
                        >
                          {props.confirmLabel}
                        </button>
                        <button
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={backToForm}
                        >
                          Atrás
                        </button>
                      </Fragment>
                      }
                      {treatmentSelected && firstImageSelected && secondImageSelected && !showPreview &&
                      <Fragment>

                        <button
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={generatePreview}
                        >
                          Generar vista previa
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={props.onCancel}
                          ref={cancelButtonRef}
                        >
                          {props.dismissLabel}
                        </button>
                      </Fragment>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
            {showPreview &&
            <div
              className={classNames(
                hideElement ? 'hidden' : '',
                'absolute align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6')}
              style={{minWidth: '1080px', minHeight: '1080px', zIndex: '-999'}}>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                  <div className="w-full">
                    {hasVerticalImages() &&
                    <div className={'px-5 pb-5'}
                         style={{minWidth: '1080px', minHeight: '1080px', maxWidth: '1080px', maxHeight: '1080px'}}
                         ref={ref}
                         id={'model'}>
                      <div className={'relative'}>
                        <p className={'absolute bottom-0 left-0'}>Antes</p>
                        {props.logo && (
                          <img className="block h-7 w-auto mt-5 mx-auto"
                               src={process.env.REACT_APP_ASSET_URL + props.logo.path}
                               alt={'Company Logo'}/>
                        )}
                        {!props.logo && (
                          <img
                            className="block h-7 w-auto mt-5 mx-auto"
                            src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                            alt="Woow logo"/>
                        )}
                        <p className={'absolute bottom-0 right-0'}>Después</p>
                      </div>
                      <div className={'grid grid-cols-2 my-3'}>
                        <img src={process.env.REACT_APP_ASSET_URL + firstImageSelected.path}
                             alt={firstImageSelected.filename} className={'pr-0.5 w-full'}/>
                        <img src={process.env.REACT_APP_ASSET_URL + secondImageSelected.path}
                             alt={secondImageSelected.filename} className={'pl-0.5 w-full'}/>
                      </div>
                      <div className={'flex flex-col'}/>
                    </div>
                    }
                    {!hasVerticalImages() &&
                    <div className={'px-5 pb-5'}
                         style={{minWidth: '1080px', minHeight: '1080px', maxWidth: '1080px', maxHeight: '1080px'}}
                         ref={ref}
                         id={'model'}>
                      <div className={'relative'}>
                        <p className={'absolute bottom-0 left-0'}>Antes</p>
                        {props.logo && (
                          <img className="block h-7 w-auto mt-5 mx-auto"
                               src={process.env.REACT_APP_ASSET_URL + props.logo.path}
                               alt={'Company Logo'}/>
                        )}
                        {!props.logo && (
                          <img
                            className="block h-7 w-auto mt-5 mx-auto"
                            src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                            alt="Woow logo"/>
                        )}
                      </div>
                      <div className={'grid grid-cols-1 my-3'}>
                        <img src={process.env.REACT_APP_ASSET_URL + firstImageSelected.path}
                             alt={firstImageSelected.filename} className={'pb-0.5 w-full'} style={{maxHeight: '95%'}}/>
                        <img src={process.env.REACT_APP_ASSET_URL + secondImageSelected.path}
                             alt={secondImageSelected.filename} className={'pt-0.5 w-full'} style={{maxHeight: '95%'}}/>
                        <span className={'text-right'} style={{marginTop: '-25px'}}>Después</span>
                      </div>
                      <div className={'flex flex-col'}>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  )
}

export default BeforeAfterGeneratorModal
