import React, { useState} from 'react'

const CompanyContext = React.createContext({
  setCompany: (company) => {},
  company: {},
  roles: [],
  isAdmin: false,
  type: 'other',
  customer: '',
  companyLogo: {
    type: '',
    filename: '',
    path: ''
  },
  woowLogo: {
    type: '',
    filename: '',
    path: ''
  },
  appLogo: {
    type: '',
    filename: '',
    path: ''
  },
  woows: []
})

const retrieveStoredCompany = () => {
  const jsonCompanyObject = localStorage.getItem('company')
  return JSON.parse(jsonCompanyObject);
}

export const CompanyContextProvider = props => {
  const companyData = retrieveStoredCompany()

  const customerHandler = (type) => {
    switch (type) {
      case 'medical':
        return 'paciente'
      case 'dental':
        return 'paciente'
      default:
        return 'cliente'
    }
  }

  const treatmentHandler = (type) => {
    switch (type) {
      case 'medical':
        return 'tratamiento'
      case 'dental':
        return 'tratamiento'
      default:
        return 'obra'
    }
  }

  let initialCompanyData = {}
  let initialRoles = []
  let initialType = ''
  let initialCompanyLogo = {
    type: '',
    filename: '',
    path: ''
  }
  let initialWoowLogo = {
    type: '',
    filename: '',
    path: ''
  }
  let initialAppLogo = {
    type: '',
    filename: '',
    path: ''
  }
  let initialWoows = []

  if (companyData) {
    initialCompanyData = companyData
    initialRoles = initialCompanyData.roles
    initialType = initialCompanyData.type
    initialWoows = companyData.woows

    companyData.logos.length &&
    companyData.logos.map(logo => {
      switch (logo.type) {
        case 'company':
          initialCompanyLogo = logo
          break
        case 'woow':
          initialWoowLogo = logo
          break
        case 'app':
          initialAppLogo = logo
          break
        default:
          break
      }

      return true
    })
  }

  const [company, setCompany] = useState(initialCompanyData)
  const [customerType, setCustomerType] = useState('cliente')
  const [treatmentType, setTreatmentType] = useState('tratamiento')
  const [roles, setRoles] = useState(initialRoles)
  const [type, setType] = useState(initialType)
  const [companyLogo, setCompanyLogo] = useState(initialCompanyLogo)
  const [woowLogo, setWoowLogo] = useState(initialWoowLogo)
  const [appLogo, setAppLogo] = useState(initialAppLogo)
  const [woows, setWoows] = useState(initialWoows)

  const isAdmin = roles.includes('admin')

  const setLogos = (logos) => {
    logos.length &&
    logos.map(logo => {
      switch (logo.type) {
        case 'main':
          return setCompanyLogo(logo)
        case 'base':
          return setWoowLogo(logo)
        case 'white':
          return setAppLogo(logo)
        default:
          return false
      }
    })
  }

  const companyHandler = (company) => {
    const customer = customerHandler(company.type)
    const treament = treatmentHandler(company.type)

    setCompany(company)
    setRoles(company.roles)
    setType(company.type)
    setCustomerType(customer)
    setTreatmentType(treament)
    setLogos(company.logos)
    setWoows(company.woows)

    const jsonCompanyObj = JSON.stringify(company)

    localStorage.setItem('company', jsonCompanyObj)
  }

  const contextValue = {
    setCompany: companyHandler,
    company: company,
    roles: roles,
    isAdmin: isAdmin,
    type: type,
    customer: customerType,
    treatment: treatmentType,
    companyLogo: companyLogo,
    woowLogo: woowLogo,
    appLogo: appLogo,
    woows: woows
  }

  return (
    <CompanyContext.Provider value={contextValue}>
      {props.children}
    </CompanyContext.Provider>
  )
}

export default CompanyContext
