import FeaturedWoow from '../../components/Company/Woows/FeaturedWoow'
import { capitalize } from '../../components/Helpers/utils'
import Container from '../../components/Layout/Container'
import CompanyContext from '../../store/company-context'
import { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'

const CompanyFeaturedWoowsPage = () => {
  document.title = 'WOOW\'s destacados | WOOW'

  const companyCtx = useContext(CompanyContext)

  const userStr = capitalize(companyCtx.customer, true)
  const singleUserStr = capitalize(companyCtx.customer, false)
  const [appLogo, setAppLogo] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  useEffect(() => {
    companyCtx.company.logos.forEach(logo => {
      switch (logo.type) {
        case 'company':
          setCompanyLogo(logo.path)
          break
        case 'app':
          setAppLogo(logo.path)
          break
        default:
          break
      }
    })
  }, [companyCtx.company.logos])

  return (
    <Layout customerStr={userStr} isAdmin={companyCtx.isAdmin} name={companyCtx.company.name} companyLogo={companyLogo}
            appLogo={appLogo}>
      <Container header={{title: 'WOOW destacados', classes: 'font-lexendSemiBold text-4xl'}}
                 classes={'py-6 max-w-[calc(100%+1rem)] mx-7 px-4 sm:px-6 md:px-8'}>
        <div className="md:flex w-full py-2 justify-between">
          <FeaturedWoow customerStr={singleUserStr}/>
        </div>
      </Container>
    </Layout>
          )
}

export default CompanyFeaturedWoowsPage
