import ButtonWithLeadingIcon from './ButtonWithLeadingIcon'
import { XIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

const FileInput = React.forwardRef((props, ref) => {
  const name = ref.current && ref.current.files[0]
    ? ref.current.files[0].name
    : ''
  const [fileName, setFileName] = useState(name)

  const updateName = () => {
    setFileName(ref.current.files[0].name)
  }

  const clearInput = () => {
    ref.current.value = ''
    if (props.filename && props.filename.length) {
      setFileName(props.filename)
    } else {
      setFileName('')
    }
  }

  const getAdditionalText = () => {
    if (!props.withoutFileName && fileName !== '') {
      return (
        <p className="font-lexendSemiBold text-gray-600 inline-flex items-center">
          {fileName} <XIcon className="h-4 w-4 ml-2 cursor-pointer" onClick={clearInput}/>
        </p>
      )
    } else if (!props.withoutFileName && props.filename && props.filename.length > 0) {
      return (
        <p className="font-lexendSemiBold text-gray-600 inline-flex items-center">
          {props.filename}
        </p>
      )
    } else if (!props.withoutFileName) {
      return (
        <p className="font-lexendSemiBold text-gray-600 inline-flex items-center">
          Ningún archivo seleccionado
        </p>
      )
    }
  }

  return (
    <div className="sm:flex sm:space-x-4 items-center">
      <ButtonWithLeadingIcon text={props.btnText} click={() => ref.current.click()} classes={props.btnClasses}
                             leadingIcon={props.buttonIcon || false}/>

      {getAdditionalText()}

      <input
        accept={props.accept}
        id={props.name}
        name={props.name}
        type="file"
        ref={ref}
        onChange={updateName}
        className={'hidden'}
        multiple={false}/>

      {props.addUploadButton && !!name.length &&
      <ButtonWithLeadingIcon text={'Subir'} leadingIcon={''} click={props.submitHandler}
                             classes={'bg-primary-default text-white px-12 hover:text-primary-default'}/>}
    </div>
  )
})

export default FileInput
