import ButtonWithLeadingIcon from '../../UI/ButtonWithLeadingIcon'
import { useToasts } from 'react-toast-notifications'
import AuthContext from '../../../store/auth-context'
import InputWithError from '../../UI/InputWithError'
import { useContext, useRef, useState } from 'react'
import LoadingSpinner from '../../UI/LoadingSpinner'

const ResetPasswordProfileForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    current_password: '',
    password: '',
    password_confirmation: ''
  })

  const authCtx = useContext(AuthContext)

  const currentPasswordInputRef = useRef()
  const passwordInputRef = useRef()
  const passwordConfirmInputRef = useRef()

  const {addToast} = useToasts()

  const updateHandler = () => {
    const enteredCurrentPassword = currentPasswordInputRef.current.value
    const enteredPassword = passwordInputRef.current.value
    const enteredPasswordConfirm = passwordConfirmInputRef.current.value

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL + '/change-password', {
      method: 'POST',
      body: JSON.stringify({
        current_password: enteredCurrentPassword,
        password: enteredPassword,
        password_confirmation: enteredPasswordConfirm
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authCtx.authToken
      }
    }).then(res => {
      setIsLoading(false)
      setErrors({
        current_password: '',
        password: '',
        password_confirmation: ''
      })
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then((data) => {
          throw data
        })
      }

    }).then((data) => {
      addToast(data.message, {appearance: 'success', autoDismiss: true,})
    }).catch(err => {
      if (err.errors) {
        setErrors({
          current_password: err.errors.current_password && err.errors.current_password[0],
          password: err.errors.password && err.errors.password[0],
          password_confirmation: err.errors.password_confirmation && err.errors.password_confirmation[0]
        })

        addToast(err.errors[Object.keys(err.errors)[0]], {appearance: 'error', autoDismiss: true,})
      } else {
        addToast(err.message, {appearance: 'error', autoDismiss: true,})
      }
    })
  }

  return (
    <div className="flex items-center justify-center shadow-md">
      <div className="bg-white w-full">
        <div className={'border-b'}>
          <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pt-6 pb-4 px-10 items-center">
            <p className={'font-lexendSemiBold text-lg'}>Contraseña actual:</p>
            <InputWithError label={''} type={'password'} name={'current_password'} id={'current-password-input'}
                            required={true}
                            setRef={currentPasswordInputRef} placeholder={'************'}
                            errorMessage={errors.current_password}
                            description={'password actual'}/>
          </div>
          <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 py-4 px-10 items-center">
            <p className={'font-lexendSemiBold text-lg'}>Nueva Contraseña</p>
            <InputWithError label={''} type={'password'} name={'password'} id={'password-input'} required={true}
                            setRef={passwordInputRef} placeholder={'************'} errorMessage={errors.password}
                            description={'nueva contraseña'}/>
          </div>
          <div className="md:grid md:grid-cols-3 md:space-y-0 space-y-1 pb-6 pt-4 px-10 items-center border-b">
            <p className={'font-lexendSemiBold text-lg'}>Repetir nueva contraseña</p>
            <InputWithError label={''} type={'password'} name={'password_confirmation'}
                            id={'password-confirmation-input'}
                            required={true}
                            setRef={passwordConfirmInputRef} placeholder={'************'}
                            errorMessage={errors.password_confirmation}
                            description={'confirmación nueva password'}/>
          </div>
        </div>
        <div className={'py-6 px-8'}>
          {!isLoading && <ButtonWithLeadingIcon text={'Actualizar contraseña'} leadingIcon={''}
                                                classes={'bg-primary-default text-white px-12 hover:text-primary-default'}
                                                click={updateHandler}/>}
          {isLoading && <LoadingSpinner/>}
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordProfileForm
