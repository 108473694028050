import ButtonWithLeadingIcon from './ButtonWithLeadingIcon'
import React from 'react'

const GalleryPhotoInput = React.forwardRef((props, ref) => {
  const handleChange = () => {
    const index = props.index | null

    props.inputHandler(index)
  }

  const clickHandler = (id) => {
    ref.current.value = ''
    props.setterId(id)
    ref.current.click()
  }

  return (
    <div className="sm:flex space-x-4 items-center">
      <ButtonWithLeadingIcon text={props.btnText} click={() => clickHandler(props.idNumber)} classes={props.btnClasses}
                             leadingIcon={props.buttonIcon || false}/>

      <input
        accept={props.accept}
        id={props.name}
        name={props.name}
        type="file"
        onChange={handleChange}
        ref={ref}
        className={'hidden'}
        multiple={false}/>
    </div>
  )
})

export default GalleryPhotoInput
