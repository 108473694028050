import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactCompareImage from 'react-compare-image'

const PreviewWoowPage = () => {
  const {uuid} = useParams()

  const [woow, setWoow] = useState()
  const [logo, setLogo] = useState()

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/get-woow/' + uuid
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    }).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        window.location.href = process.env.REACT_APP_CALLBACK
      }
    }).then((data) => {
      setWoow(data.woow)
      setLogo(data.logo)
    }).catch((err) => {
      console.log(err)
    })
  }, [uuid])

  return (
    <div className={'flex align-middle justify-center'}>
      <div
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
            <div className="w-full">
              <div className={'flex flex-wrap content-center justify-center'}>
                {woow && (
                  <Fragment>
                    <ReactCompareImage leftImage={process.env.REACT_APP_ASSET_URL + woow.before_photo.path}
                                       rightImage={process.env.REACT_APP_ASSET_URL + woow.after_photo.path}
                                       vertical={woow.is_vertical_slider} sliderLineWidth={6} aspectRatio="wider"
                                       leftImageCss={{objectFit: 'contain', objectPosition: 'top'}}
                                       rightImageCss={{objectFit: 'contain', objectPosition: 'top'}}/>
                    {logo && (
                      <img className="h-16 w-auto mt-5 mb-3" src={process.env.REACT_APP_ASSET_URL + logo.path}
                           alt={'Company Logo'}/>
                    )}
                    {!logo && (
                      <img
                        className="h-10 w-auto mt-5 mb-3"
                        src={process.env.REACT_APP_CALLBACK + '/images/logo-woow-primary-color.svg'}
                        alt="WOOW logo"/>
                    )}
                    <p className={'text-xl text-center w-full'}>{woow.title}</p>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewWoowPage
