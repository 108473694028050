import { useCallback, useRef, useState } from 'react'
import { getCroppedImg } from '../Helpers/canvaUtils'
import LoadingSpinner from './LoadingSpinner'
import ModalTemplate from './ModalTemplate'
import { Switch } from '@headlessui/react'
import Cropper from 'react-easy-crop'
import Slider from 'rc-slider'

import 'rc-slider/assets/index.css'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ImageCropModal = props => {
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [sliderPosition, setSliderPosition] = useState(zoom)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [enabled, setEnabled] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(108 / 53)
  const [cropping, setCropping] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const cancelButtonRef = useRef()

  const showCroppedImage = useCallback(async () => {
    setCropping(true)

    try {
      const croppedImage = await getCroppedImg(
        props.img,
        croppedAreaPixels
      )

      setCropping(false)

      props.onSubmit(props.treatmentId, croppedImage, +enabled)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, enabled, props])

  const aspectRatioHandler = () => {
    setEnabled(!enabled)
    aspectRatio === 1 / 2
      ? setAspectRatio(108 / 53)
      : setAspectRatio(1 / 2)
  }

  const updateZoom = (value) => {
    setZoom(value)
    setSliderPosition(value)
  }

  return (
    <ModalTemplate open={props.open} setRef={props.setRef} onCancel={props.onCancel}>
      <div
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <div className="w-full relative" style={{height: '250px'}}>
              <Cropper
                image={props.img}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={updateZoom}
              />
            </div>
            <Slider
              value={sliderPosition}
              min={1}
              max={3}
              step={0.01}
              onChange={updateZoom}
              className={'h-4 mt-5'}
            />
            <Switch.Group as="div" className="flex items-center mt-5">
              <Switch
                checked={enabled}
                onChange={aspectRatioHandler}
                className={classNames(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                )}
              >
                <span className="sr-only">Relación de aspecto</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-900">Relación de aspecto vertical </span>
                <span className="text-sm text-gray-500">(1/2)</span>
              </Switch.Label>
            </Switch.Group>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              {(props.isLoading || cropping) && <LoadingSpinner/>}
              {!props.isLoading && !cropping &&
              <>
                <button
                  type="button"
                  onClick={showCroppedImage}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-secondary-default hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {props.confirmLabel}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={props.onCancel}
                  ref={cancelButtonRef}
                >
                  {props.dismissLabel}
                </button>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ImageCropModal
