import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

const InputWithError = (props) => {
  let inputClasses = 'bg-gray-10 shadow-sm block w-full p-4 sm:text-sm rounded-md border-gray-50'

  if (props.errorMessage) {
    inputClasses += ' text-red-900 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
  }

  return (
    <Fragment>
      {props.label && (
        <label htmlFor={props.name || ''} className="font-bold">
          {props.label || 'Placeholder label'} {props.required && <span style={{'color': 'red'}}>*</span>}
        </label>
      )}
      <div className="relative rounded-md shadow-sm">
        <input
          type={props.type || 'text'}
          name={props.name || ''}
          id={props.id || ''}
          className={inputClasses}
          placeholder={props.placeholder || ''}
          defaultValue={props.default || ''}
          aria-invalid={!!props.errorMessage}
          aria-describedby={props.errorMessage ? props.name + '_error' : props.description || ''}
          required={props.required || false}
          ref={props.setRef}
          readOnly={props.readonly || false}
        />
        {props.errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
          </div>
        )}
      </div>
      {props.errorMessage && (
        <p className="mt-2 text-sm text-red-600 pl-2" id="email-error">
          {props.errorMessage}
        </p>
      )}
    </Fragment>
  )
}

export default InputWithError
