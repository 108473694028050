import Paginator from '../UI/Paginator'
import { Fragment } from 'react'
import moment from 'moment'

const List = props => (
  <Fragment>
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                {props.headers.map((header, i) => (
                  <th scope="col" key={i} className="px-6 py-3 text-left uppercase font-medium text-sm tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {props.companies && props.companies.map((company) => (
                <tr key={company.id}>
                  <td className="px-6 py-4 whitespace-nowrap font-lexendSemiBold">{
                    company.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {company.users.length || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {company.woows.length || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {company.images.length || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {company.subscription ? company.subscription.name : 'WOOW Basic'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {moment(company.created_at).format('DD-MM-yy')}
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap">
                    {company.last_login_at
                      ? moment(company.last_login_at).format('DD-MM-yy')
                      : 'No data'
                    }
                  </td>
                </tr>
              ))}
              {!props.companies && (
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-lexendSemiBold" colSpan={6}>
                    No existen registros
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    {props.links && props.meta && (
      <Paginator links={props.links} meta={props.meta} onPagination={props.onPagination}/>
    )}
  </Fragment>
)

export default List
