import SearchInputWithLeadingIcon from '../../components/UI/SearchInputWithLeadingIcon'
import ButtonWithLeadingIcon from '../../components/UI/ButtonWithLeadingIcon'
import { ArrowDownIcon, SearchIcon } from '@heroicons/react/outline'
import { downloadCsvFile } from '../../Services/ExportDataService'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import { sendRequest } from '../../Services/RequestService'
import { capitalize } from '../../components/Helpers/utils'
import Container from '../../components/Layout/Container'
import CompanyContext from '../../store/company-context'
import { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import AuthContext from '../../store/auth-context'
import List from '../../components/Admin/List'

const AdminDashboardPage = () => {
  document.title = 'Panel de administración | WOOW'

  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)
  const authToken = authCtx.authToken

  const [companies, setCompanies] = useState({})
  const [appLogo, setAppLogo] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  useEffect(() => {
    companyCtx.company.logos.forEach(logo => {
      switch (logo.type) {
        case 'company':
          setCompanyLogo(logo.path)
          break
        case 'app':
          setAppLogo(logo.path)
          break
        default:
          break
      }
    })
  }, [companyCtx.company.logos])

  const userStr = capitalize(companyCtx.customer, true)

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/companies'
    sendRequest(authToken, url).then(data => {
      setCompanies(data)
    })
  }, [authToken])

  const setCompaniesWithRequest = url => {
    sendRequest(authToken, url).then(data => {
      setCompanies(data)
    })
  }

  const paginationHandler = event => {
    const url = event.target.dataset.url
    setCompaniesWithRequest(url)
  }

  const searchHandler = event => {
    const url = process.env.REACT_APP_API_URL + '/companies?filter[search]=' + event
    setCompaniesWithRequest(url)
  }

  const exportDataHandler = () => {
    const url = process.env.REACT_APP_API_URL + '/companies/csv'
    downloadCsvFile(authToken, url).then()
  }

  return (
    <Layout customerStr={userStr} isAdmin={companyCtx.isAdmin} name={companyCtx.company.name} companyLogo={companyLogo}
            appLogo={appLogo}>
      <Container header={{title: userStr, classes: 'font-lexendSemiBold text-4xl'}}
                 classes={'py-6 max-w-[calc(100%+1rem)] mx-7 px-4 sm:px-6 md:px-8'}>
        <div className="md:flex w-full py-2 justify-between gap-x-5">
          <SearchInputWithLeadingIcon userStr={userStr} aria-hidden="true" onSearch={searchHandler}
                                      leadingIcon={<SearchIcon className="h-5 w-5 text-gray-400"/>} width={'w-3/5'}
                                      placeholder={'Buscar ' + userStr}/>
          <ButtonWithLeadingIcon text={'Descargar todos los datos'}
                                 leadingIcon={<ArrowDownIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>}
                                 classes={'bg-blue-100 text-secondary-default px-7'} click={exportDataHandler}/>
        </div>
        {Object.entries(companies).length > 0
          ? <List companies={companies.data} links={companies.links} meta={companies.meta}
                  headers={['Nombres', 'Clientes', 'WOOW\'s', 'Antes y Después', 'Plan', 'Alta', 'Último login']}
                  onPagination={paginationHandler}/>
          : <LoadingSpinner/>
        }
      </Container>
    </Layout>
  )
}

export default AdminDashboardPage
