import UserInformation from '../../components/Company/Users/UserInformation'
import { useCallback, useContext, useEffect, useState } from 'react'
import UserPhotos from '../../components/Company/Users/UserPhotos'
import UserImages from '../../components/Company/Users/UserImages'
import UserWoows from '../../components/Company/Users/UserWoows'
import { capitalize } from '../../components/Helpers/utils'
import Container from '../../components/Layout/Container'
import CompanyContext from '../../store/company-context'
import Layout from '../../components/Layout/Layout'
import { useParams } from 'react-router-dom'
import Tabs from '../../components/UI/Tabs'

let user = {name: '', last_name: ''}

const CompanyUserPage = () => {
  const {userId} = useParams()

  const companyCtx = useContext(CompanyContext)

  const userStr = capitalize(companyCtx.customer, true)

  const [tabs, setTabs] = useState([
    {name: 'Información personal', href: '#info', current: true},
    {name: 'Galería de fotos', href: '#photos', current: false},
    {name: 'WOOW\'s', href: '#woows', current: false},
    {name: 'Antes y Después', href: '#images', current: false}
  ])
  const [appLogo, setAppLogo] = useState()
  const [companyLogo, setCompanyLogo] = useState()

  useEffect(() => {
    companyCtx.company.logos.forEach(logo => {
      switch (logo.type) {
        case 'company':
          setCompanyLogo(logo.path)
          break
        case 'app':
          setAppLogo(logo.path)
          break
        default:
          break
      }
    })
  }, [companyCtx.company.logos])

  const tabSelectedHandler = useCallback((tab) => {
    const updatedTabs = tabs.map(element => {
      if (typeof tab === 'string') {
        tab = JSON.parse(tab)
      }

      if (element.current === true && element.href !== tab.href) {
        return {...element, current: false}
      } else if (element.current === false && element.href === tab.href) {
        return {...element, current: true}
      }

      return element
    })

    setTabs(updatedTabs)
  }, [tabs])

  user = companyCtx.company.users.find(user => user.id === +userId)

  const updateUserHandler = (newUser) => {
    user = newUser
  }

  document.title = 'Perfil de usuario | WOOW'

  return (
    <Layout customerStr={userStr} isAdmin={companyCtx.isAdmin} name={companyCtx.company.name} companyLogo={companyLogo}
            appLogo={appLogo}>
      <Container
        header={{title: user.name + ' ' + user.last_name, classes: 'font-lexendSemiBold text-4xl flex-1'}}
        classes={'py-6 max-w-[calc(100%+1rem)] mx-7 px-4 sm:px-6 md:px-8 flex-1'} withAvatar={true} userLogo={user.avatar}>
        <Tabs tabs={tabs} selectedTab={tabSelectedHandler} user={user}>
          {tabs.map(tab => {
            if (tab.current && tab.href === '#info') {
              return <UserInformation key={tab.href} user={user} onUpdate={updateUserHandler} selectedTab={tabSelectedHandler}/>
            } else if (tab.current && tab.href === '#photos') {
              return <UserPhotos key={tab.href} user={user}/>
            } else if (tab.current && tab.href === '#woows') {
              return <UserWoows key={tab.href} user={user} selectedTab={tabSelectedHandler}/>
            } else if (tab.current && tab.href === '#images') {
              return <UserImages key={tab.href} user={user}/>
            }

            return false
          })}
        </Tabs>
      </Container>
    </Layout>
  )
}

export default CompanyUserPage
