import React, { useState, useEffect, useCallback } from 'react'

let logoutTimer

const AuthContext = React.createContext({
  tokenType: '',
  token: '',
  authToken: '',
  isLoggedIn: false,
  login: (tokenType, token, expirationTime) => {},
  logout: () => {}
})

const calculateRemainingTime = expirationTime => {
  const currentTime = new Date().getTime()
  const adjExpirationTime = new Date(expirationTime).getTime()

  // return adjExpirationTime - currentTime
  // TODO: hacky code. Fix method needed
  return 6000000001
}

const retrieveStoredToken = () => {
  const storedTokenType = localStorage.getItem('tokenType')
  const storedToken = localStorage.getItem('token')
  const storedExpirationDate = localStorage.getItem('expirationTime')

  const remainingTime = calculateRemainingTime(storedExpirationDate)

  if (remainingTime <= 60000) {
    localStorage.removeItem('tokenType')
    localStorage.removeItem('key')
    localStorage.removeItem('expirationTime')
    localStorage.removeItem('company')
    localStorage.removeItem('plans')
    return null
  }

  return {
    tokenType: storedTokenType,
    token: storedToken,
    duration: remainingTime
  }
}

export const AuthContextProvider = props => {
  const tokenData = retrieveStoredToken()

  let initialTokenType
  let initialToken
  if (tokenData) {
    initialToken = tokenData.token
    initialTokenType = tokenData.tokenType
  }

  const [tokenType, setTokenType] = useState(initialTokenType)
  const [token, setToken] = useState(initialToken)

  const userIsLoggedIn = !!token
  const formattedAuthToken = tokenType + ' ' + token

  const logoutHandler = useCallback(() => {
    setToken(null)
    setTokenType(null)
    localStorage.removeItem('tokenType')
    localStorage.removeItem('token')
    localStorage.removeItem('expirationTime')
    localStorage.removeItem('company')
    localStorage.removeItem('plans')

    if (logoutTimer) {
      clearTimeout(logoutTimer)
    }
  }, [])

  const loginHandler = (tokenType, token, expirationTime) => {
    setToken(token)
    setTokenType(tokenType)

    localStorage.setItem('tokenType', tokenType)
    localStorage.setItem('token', token)
    localStorage.setItem('expirationTime', expirationTime)

    const remainingTime = calculateRemainingTime(expirationTime)

    logoutTimer = setTimeout(logoutHandler, remainingTime)
  }

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration)
    }
  }, [tokenData, logoutHandler])

  const contextValue = {
    tokenType: tokenType,
    token: token,
    authToken: formattedAuthToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
